import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { Dialog, DialogTitle, FormControlLabel, Stack, Switch } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useForm, useFieldArray } from 'react-hook-form';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { doc, setDoc } from 'firebase/firestore';
import { RHFTextField } from 'src/components/hook-form';
import FormProvider from 'src/components/hook-form/FormProvider';
import { DB } from 'src/auth/FirebaseContext';
import RHFTextFieldIcon from 'src/components/hook-form/RHFTextFieldIcon';

type FormValuesProps = {
  name: string;
  values: {};
  afterSubmit?: VoidFunction;
  signleItem?: object;
  showUsers: boolean;
  userDescription: string;
  required: boolean;
};

export default function AddNewParameterModal({
  children,
  singleItem,
  campaignId,
  afterSubmit,
}: any) {
  const [open, setOpen] = useState(false);
  const handleOpen = () => {
    if (campaignId && campaignId !== 'empty') {
      setOpen(true);
    }
  };
  const handleClose = () => setOpen(false);

  const ParameterSchema = Yup.object().shape({
    name: Yup.string()
      .trim()
      .required('Name is required')
      .test('no-special-chars', 'Name should not contain special characters', (value) =>
        /^[\w\s]+$/i.test(value as any)
      )
      .notOneOf(
        ['humanAssistance', 'completed'],
        'Name cannot be "humanAssistance" or "completed"'
      ),
    values: Yup.array()
      .of(
        Yup.object().shape({
          value: Yup.string()
            .trim()
            .required('Parameter is required')
            .test('no-special-chars', 'Parameter should not contain special characters', (value) =>
              /^[\w\s]+$/i.test(value as any)
            ),
        })
      )
      .min(1, 'Add at least one Parameter')
      .required('Parameter is required'),
    userDescription: Yup.string().when('showUsers', {
      is: true,
      then: Yup.string().required('User Description is required'),
      otherwise: Yup.string().notRequired(),
    }),
    showUsers: Yup.boolean(),
    required: Yup.boolean(),
  });

  const defaultValues = {
    name: '',
    values: singleItem
      ? singleItem?.values.map((single: any) => ({ value: single }))
      : [{ value: '' }, { value: '' }],
    showUsers: singleItem ? Boolean(singleItem?.showUsers) : false,
    userDescription: singleItem ? singleItem?.userDescription : '',
    required: singleItem ? singleItem?.required : false,
  };

  const methods = useForm<FormValuesProps>({
    resolver: yupResolver(ParameterSchema),
    defaultValues,
  });
  useEffect(() => {
    if (singleItem) {
      const valueArray = singleItem?.values.map((single: any) => ({ value: single }));
      methods.setValue('name', singleItem.name);
      methods.setValue('values', valueArray);
      methods.setValue('showUsers', Boolean(singleItem?.showUsers));
      methods.setValue('userDescription', singleItem?.userDescription);
      methods.setValue('required', singleItem?.required);
    }
  }, [methods, singleItem]);

  const { handleSubmit, reset, control, getValues, watch } = methods;

  const { fields, append, remove } = useFieldArray({
    control,
    // @ts-ignore
    name: 'values',
  });

  const handleFormSubmit = async (data: any) => {
    const prmtrData = data.values;

    const values: any = prmtrData.map((single: any) => single.value);

    const newData = { ...data, values, showUsers: !!data.showUsers };

    setOpen(false);
    reset();
    let dbName;
    if (newData?.name === 'humanAssistance') {
      dbName = newData?.name;
    } else {
      dbName = newData?.name.toLowerCase().replace(' ', '_');
    }

    const campaignRef = doc(DB as any, `campaigns/${campaignId}/parameterSetup/${dbName}`);

    await setDoc(campaignRef, newData);
    if (afterSubmit) {
      afterSubmit();
    }
  };

  return (
    <>
      {React.cloneElement(children, { onClick: handleOpen })}
      <Dialog open={open} onClose={handleClose} fullWidth>
        <DialogTitle
          id="scroll-dialog-title"
          sx={{ textAlign: 'center', fontSize: '1.5rem !important', paddingBottom: 1 }}
        >
          {singleItem ? 'Edit Parameters' : 'Create New Parameters'}
        </DialogTitle>
        <Box
          sx={{
            padding: 3,
            paddingTop: 1,
            overflow: 'auto',
            '::-webkit-scrollbar': {
              display: 'none',
            },
          }}
        >
          <Stack>
            <FormProvider methods={methods} onSubmit={handleSubmit(handleFormSubmit)}>
              <RHFTextField
                disabled={Boolean(singleItem)}
                label="Name"
                name="name"
                id="outlined-size-small"
                size="small"
                sx={{ width: '100%' }}
              />
              <FormControlLabel
                control={
                  <Switch
                    checked={methods.watch('showUsers')}
                    onChange={(_, checked) => methods.setValue('showUsers', checked)}
                  />
                }
                label="Show Users"
                sx={{ marginTop: 2 }}
              />
              <FormControlLabel
                control={
                  <Switch
                    checked={methods.watch('required')}
                    onChange={(_, checked) => methods.setValue('required', checked)}
                  />
                }
                label="Required"
                sx={{ marginTop: 2 }}
              />

              {watch('showUsers') && (
                <RHFTextField
                  label="User Description"
                  name="userDescription"
                  id="userDescription"
                  size="small"
                  multiline
                  minRows={3}
                  sx={{ marginTop: 3, width: '100%' }}
                />
              )}
              <Typography variant="h6" sx={{ paddingTop: 3 }}>
                Parameters
              </Typography>
              {
                // @ts-ignore
                getValues().values?.length === 0 && (
                  <p style={{ color: 'red' }}>Add at least one Parameter</p>
                )
              }
              {fields.map((item, index) => (
                <Stack
                  key={item.id}
                  flexDirection="row"
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <RHFTextFieldIcon
                    key={item.id}
                    name={`values.${index}.value`}
                    id="outlined-size-small"
                    sx={{ mt: 1 }}
                    size="small"
                    fullWidth
                    onRemove={() => remove(index)}
                  />
                </Stack>
              ))}
              <Button
                variant="contained"
                fullWidth
                sx={{ marginTop: 1, fontSize: 15, py: 0.8 }}
                onClick={() => {
                  append({ value: '' });
                }}
              >
                Add Parameter
              </Button>
              <Stack alignItems="end" marginTop={2}>
                <Button type="submit" variant="outlined">
                  Submit
                </Button>
              </Stack>
            </FormProvider>
          </Stack>
        </Box>
      </Dialog>
    </>
  );
}
