import { useQuery } from '@tanstack/react-query';
import FirebaseService from '../services/firebaseService';
import { queryKeys } from '../utils/queryKeys';

const useSingleUser = (userId: string) =>
  useQuery<{ [key: string]: any }>({
    queryKey: [queryKeys.singleUser, userId],
    queryFn: () => new FirebaseService().getSingleUser(userId),
  });

export default useSingleUser;
