import { useState } from 'react';
import { Button } from '@mui/material';
import AddChannelModal from './Channels/AddChannelModal';
import ChannelTable from './Channels/ChannelTable';

function ChannelSetup() {
  const [channelModalOpen, setChannelModalOpen] = useState(false);

  return (
    <>
      <Button variant="outlined" onClick={() => setChannelModalOpen(true)} sx={{ my: 1 }}>
        Create Channel
      </Button>
      <ChannelTable />
      <AddChannelModal
        channelModalOpen={channelModalOpen}
        handleModalClose={() => setChannelModalOpen(false)}
      />
    </>
  );
}

export default ChannelSetup;
