import ReactDOM from 'react-dom/client';
// eslint-disable-next-line import/no-extraneous-dependencies
import * as Sentry from '@sentry/browser';
import App from './App';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import reportWebVitals from './reportWebVitals';

// ----------------------------------------------------------------------

Sentry.init({
    dsn: 'https://2d7ae3e39fb54b62b6f1e1b1d45a90e3@o4504286862180352.ingest.sentry.io/4504286864015360',
});
const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

root.render(<App />);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.unregister();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
