/* eslint-disable no-unsafe-optional-chaining */
import React, { useState } from 'react';
import {
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Paper,
  Box,
  IconButton,
  MenuItem,
} from '@mui/material';
import { TableVirtuoso, TableComponents } from 'react-virtuoso';
import { useSnackbar } from 'notistack';
import Iconify from 'src/components/iconify/Iconify';
import MenuPopover from 'src/components/menu-popover/MenuPopover';
import { deleteDoc, doc } from 'firebase/firestore';
import { DB } from 'src/auth/FirebaseContext';
import { useQueryClient } from '@tanstack/react-query';
import { queryKeys } from 'src/utils/queryKeys';
import useFaqs from 'src/hooks/useFaqs';
import DeleteConfirmationModal from 'src/sections/chat/header/DeleteConfirmationModal';
import { getFirebaseDateTime } from 'src/utils/helperFunctions';
import useSingleUser from 'src/hooks/useSingleUser';
import EditUserModal from './EditFaq';

const TableBodyComponent: React.ComponentType<any> = React.forwardRef<HTMLTableSectionElement>(
  (props, ref) => <TableBody {...props} ref={ref} />
);

const ScrollerComponent: React.ComponentType<any> = React.forwardRef<HTMLDivElement>(
  (props, ref) => <TableContainer component={Paper} {...props} ref={ref} />
);

const VirtuosoTableComponents: TableComponents = {
  Scroller: ScrollerComponent,
  Table: (props) => <Table {...props} sx={{ borderCollapse: 'separate', tableLayout: 'fixed' }} />,
  TableHead: TableHead as React.ComponentType<any>,
  // eslint-disable-next-line react/prop-types
  TableRow: ({ item: _item, ...props }) => <TableRow {...props} />,
  TableBody: TableBodyComponent,
};

export default function FaqTable() {
  const [openPopover, setOpenPopover] = useState<HTMLElement | null>(null);
  const [openEditModal, setOpenEditModal] = useState(false);
  const [selectedFaq, setSelectedFaq] = useState('');
  const [deleteFaqLoading, setDeleteFaqLoading] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const queryClient = useQueryClient();
  const { data } = useFaqs();
  const faqData = data?.map((Faq: any) => Faq) || [];
  const { data: userData } = useSingleUser(faqData?.[0]?.updatedBy);

  const handleOpenPopover = (event: React.MouseEvent<HTMLElement>, index: any): void => {
    setOpenPopover(event.currentTarget);
    setSelectedFaq(faqData[index]?.id);
  };

  const handleClosePopover = () => {
    setOpenPopover(null);
  };

  const handleDelete = async () => {
    if (!selectedFaq) return;
    try {
      setDeleteFaqLoading(true);
      const docRef = doc(DB, `faq`, selectedFaq);
      await deleteDoc(docRef);
      queryClient.invalidateQueries({ queryKey: [queryKeys.faq] });
      setSelectedFaq('');
      enqueueSnackbar('Faq Deleted', { variant: 'success' });
    } catch (error) {
      enqueueSnackbar('Faq Deletion Failed', { variant: 'error' });
    } finally {
      setDeleteFaqLoading(false);
      setSelectedFaq('');
    }
  };

  const headLabel = [
    { id: 'title', label: 'Title', width: 200 },
    { id: 'question', label: 'Question', width: 200 },
    { id: 'answer', label: 'Answer', width: 200 },
    { id: 'url', label: 'URL', width: 300 },
    { id: 'updatedBy', label: 'Updated By', width: 150 },
    { id: 'createdAt', label: 'Created At', width: 150 },
    { id: 'updatedAt', label: 'Updated At', width: 150 },
    { id: '' },
  ];

  return (
    <Box style={{ height: '75vh', width: '100%' }}>
      <TableVirtuoso
        data={faqData}
        components={VirtuosoTableComponents}
        fixedHeaderContent={() => (
          <TableRow>
            {headLabel.map((head) => (
              <TableCell
                key={head.id}
                align="left"
                style={{}}
                sx={{
                  backgroundColor: 'background.paper',
                  width: head?.width,
                }}
              >
                {head.label}
              </TableCell>
            ))}
          </TableRow>
        )}
        itemContent={(index) => (
          <>
            <TableCell style={{ textOverflow: 'ellipsis', overflow: 'hidden' }} align="left">
              {faqData[index]?.title}
            </TableCell>
            <TableCell style={{ textOverflow: 'ellipsis', overflow: 'hidden' }} align="left">
              {faqData[index]?.question}
            </TableCell>
            <TableCell style={{ textOverflow: 'ellipsis', overflow: 'hidden' }} align="left">
              {faqData[index]?.answer}
            </TableCell>
            <TableCell
              style={{
                textOverflow: 'ellipsis',
                overflow: 'hidden',
              }}
              align="left"
            >
              {faqData[index]?.url}
            </TableCell>
            <TableCell style={{ textOverflow: 'ellipsis', overflow: 'hidden' }} align="left">
              {userData?.displayName}
            </TableCell>
            <TableCell style={{ textOverflow: 'ellipsis', overflow: 'hidden' }} align="left">
              {getFirebaseDateTime(faqData?.[index]?.createdAt, 'D-MM-YYYY h:mm a')}
            </TableCell>
            <TableCell style={{ textOverflow: 'ellipsis', overflow: 'hidden' }} align="left">
              {getFirebaseDateTime(faqData?.[index]?.updatedAt, 'D-MM-YYYY h:mm a')}
            </TableCell>

            <TableCell align="right">
              <IconButton
                color={openPopover ? 'inherit' : 'default'}
                onClick={(event) => handleOpenPopover(event, index)}
              >
                <Iconify icon="eva:more-vertical-fill" />
              </IconButton>
            </TableCell>
          </>
        )}
      />
      <MenuPopover
        open={openPopover}
        onClose={handleClosePopover}
        arrow="right-top"
        sx={{ width: 160 }}
      >
        <MenuItem
          onClick={() => {
            setOpenEditModal(true);
            handleClosePopover();
          }}
        >
          <Iconify icon="bxs:edit" />
          Edit
        </MenuItem>
        <MenuItem sx={{ color: 'error.main' }}>
          <DeleteConfirmationModal
            title="Faq Deletion"
            content="Are you sure you want to delete this Faq?"
            handleDelete={() => {
              handleDelete();
              handleClosePopover();
            }}
            loading={deleteFaqLoading}
          >
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <Iconify icon="eva:trash-2-outline" />
              Delete
            </Box>
          </DeleteConfirmationModal>
        </MenuItem>
      </MenuPopover>
      <EditUserModal
        FaqModalOpen={openEditModal}
        handleModalClose={() => setOpenEditModal(false)}
        selectedFaq={selectedFaq}
        setSelectedFaq={setSelectedFaq}
      />
    </Box>
  );
}
