import { useEffect, useState, useCallback } from 'react';
// @mui
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import DialogTitle from '@mui/material/DialogTitle';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import Dialog, { DialogProps } from '@mui/material/Dialog';
// components
import Iconify from 'src/components/iconify';
import { ref, uploadBytes } from 'firebase/storage';
import { storage } from 'src/auth/FirebaseContext';
import { useSnackbar } from 'notistack';
import { LoadingButton } from '@mui/lab';
import { allowedFileTypes } from 'src/utils/enums';
import { Upload } from './upload';

// ----------------------------------------------------------------------

interface Props extends DialogProps {
  title?: string;
  //
  onCreate?: VoidFunction;
  onUpdate?: VoidFunction;
  //
  folderName?: string;
  onChangeFolderName?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  //
  open: boolean;
  onClose: VoidFunction;
  fetchFiles: VoidFunction;
}

export default function FileManagerNewFolderDialog({
  title = 'Upload Files',
  open,
  onClose,
  //
  onCreate,
  onUpdate,
  //
  folderName,
  onChangeFolderName,
  fetchFiles,
  ...other
}: Props) {
  const [files, setFiles] = useState<(File | string)[]>([]);
  const [loading, setLoading] = useState(false); // Added loading state
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    if (!open) {
      setFiles([]);
    }
  }, [open]);

  const handleDrop = useCallback(
    (acceptedFiles: File[]) => {
      const validFiles = acceptedFiles.filter((file) => allowedFileTypes.includes(file.type));
      if (validFiles.length !== acceptedFiles.length) {
        enqueueSnackbar('Please upload PDF, HTML, TXT, CSV, DOCX, or PPTX files only.', {
          variant: 'warning',
        });
      }
      const newFiles = validFiles.map((file) =>
        Object.assign(file, {
          preview: URL.createObjectURL(file),
        })
      );

      setFiles([...files, ...newFiles]);
    },
    [enqueueSnackbar, files]
  );

  const handleUpload = async () => {
    if (files.length === 0) {
      enqueueSnackbar('No files selected', { variant: 'warning' });
      return; // Don't close the modal if no files are selected
    }

    setLoading(true); // Set loading to true when the upload starts

    try {
      const uploadPromises = files.map((file) => {
        if (typeof file !== 'string') {
          // Upload each file to Firebase Storage under the 'train' folder
          const storageRef = ref(storage, `train/${file.name}`);
          return uploadBytes(storageRef, file);
        }

        // Return a resolved Promise if the file is a string
        return Promise.resolve();
      });

      // Wait for all files to upload
      await Promise.all(uploadPromises);
      fetchFiles();

      enqueueSnackbar('All files uploaded successfully', { variant: 'success' });

      // Set loading to false after the upload is complete
      setLoading(false);

      // Close the modal only after all files are uploaded
      onClose();
    } catch (error) {
      enqueueSnackbar('Upload failed', { variant: 'error' });
      setLoading(false); // Set loading to false in case of an error
    }
  };

  const handleRemoveFile = (inputFile: File | string) => {
    const filtered = files.filter((file) => file !== inputFile);
    setFiles(filtered);
  };

  const handleRemoveAllFiles = () => {
    setFiles([]);
  };

  return (
    <Dialog fullWidth maxWidth="sm" open={open} onClose={onClose} {...other}>
      <DialogTitle sx={{ p: (theme) => theme.spacing(3, 3, 2, 3) }}> {title} </DialogTitle>

      <DialogContent dividers sx={{ pt: 1, pb: 0, border: 'none' }}>
        {(onCreate || onUpdate) && (
          <TextField
            fullWidth
            label="Folder name"
            value={folderName}
            onChange={onChangeFolderName}
            sx={{ mb: 3 }}
          />
        )}

        <Upload multiple files={files} onDrop={handleDrop} onRemove={handleRemoveFile} />
      </DialogContent>

      <DialogActions>
        <LoadingButton
          loading={loading}
          variant="contained"
          startIcon={<Iconify icon="eva:cloud-upload-fill" />}
          onClick={handleUpload}
          disabled={loading} // Disable the button when loading
        >
          Upload
        </LoadingButton>

        {!!files.length && (
          <Button
            variant="outlined"
            color="inherit"
            onClick={handleRemoveAllFiles}
            disabled={loading}
          >
            Remove all
          </Button>
        )}

        {(onCreate || onUpdate) && (
          <Stack direction="row" justifyContent="flex-end" flexGrow={1}>
            <Button variant="soft" onClick={onCreate || onUpdate}>
              {onUpdate ? 'Save' : 'Create'}
            </Button>
          </Stack>
        )}
      </DialogActions>
    </Dialog>
  );
}
