import { Container, Typography } from '@mui/material';
import { ForbiddenIllustration } from 'src/assets/illustrations';
import { m } from 'framer-motion';
import { varBounce } from 'src/components/animate';

export default function ParameterSettings() {
  return (
    <Container
      sx={{
        textAlign: 'center',
        height: '100vh',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <m.div variants={varBounce().in}>
        <Typography variant="h3" paragraph>
          Permission Denied
        </Typography>
      </m.div>

      <m.div variants={varBounce().in}>
        <Typography sx={{ color: 'text.secondary' }}>
          You do not have permission to access this page.
        </Typography>
      </m.div>

      <m.div variants={varBounce().in}>
        <ForbiddenIllustration sx={{ height: 260, my: { xs: 5, sm: 10 } }} />
      </m.div>
    </Container>
  );
}
