import { Dayjs } from 'dayjs';

export type IFormValuesProps = {
  id: string;
  name: string;
  service: string;
  dateAndTime: Dayjs | string;
  templateId: string;
  serviceId?: string;
  group: string;
  isPassed?: boolean;
};

export type Props = {
  items?: IFormValuesProps[];
  campaignId?: any;
  fetchNotifications?: any;
};

export const notificationobj = {
  id: Date.now().toString(),
  name: '',
  service: '',
  dateAndTime: '',
  templateId: '',
  group: '',
};

export const serviceType = [
  {
    label: 'EMAIL',
    value: 'EMAIL',
  },
  {
    label: 'SMS',
    value: 'SMS',
  },
];
