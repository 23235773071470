/* eslint-disable no-restricted-syntax */
import { Autocomplete, Chip, FormControl, SxProps, TextField, Typography } from '@mui/material';
import { collection, DocumentData, QueryDocumentSnapshot } from 'firebase/firestore';
import { useEffect } from 'react';
import { useCollection } from 'react-firebase-hooks/firestore';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { DB } from 'src/auth/FirebaseContext';
import { useAuthContext } from 'src/auth/useAuthContext';
import { useChat } from 'src/context/ChatContext';
import useAllUnreadHumanAssistanceContacts from 'src/hooks/useAllUnReadHumanAssistanceContacts';
import useEffectSkipFirst from 'src/hooks/useEffectSkipFirst';
import { User_Role } from 'src/utils/enums';

interface ComponentProps {
  sx?: SxProps;
}
const CampaignSelector = ({ sx }: ComponentProps) => {
  const [campaigns, loading] = useCollection(collection(DB, 'campaigns'));
  const { profile } = useAuthContext();
  const [searchParams] = useSearchParams();
  const { contactID } = useChat();
  const { changeCampaignID, changeContactID, campaignID } = useChat();
  const ParamsCampaignId = searchParams.get('conversationId') || campaignID;
  const navigate = useNavigate();
  // fireabse has doesnot query data on basis of field which is not present in document so it gives empty array
  // thats why filtering it locally
  const campaignOptions =
    profile?.role === User_Role.support
      ? profile?.campaigns
          ?.map((item: any) => {
            const matchingCampaign = campaigns?.docs?.find(
              (single: QueryDocumentSnapshot<DocumentData>) => single.id === item
            );
            return matchingCampaign
              ? { label: matchingCampaign?.data()?.campaignName || '', value: matchingCampaign.id }
              : null;
          })
          .filter(Boolean)
          .sort((a: any, b: any) => (a.label || '').localeCompare(b.label || ''))
      : campaigns?.docs
          ?.map((single: QueryDocumentSnapshot<DocumentData>) => {
            const campaignData = single?.data();
            return { label: campaignData.campaignName || '', value: single.id };
          })
          .filter(Boolean)
          .sort((a: any, b: any) => (a.label || '').localeCompare(b.label || ''));
  const { data } = useAllUnreadHumanAssistanceContacts(campaignOptions);


  useEffect(() => {
    if (ParamsCampaignId !== 'empty' && campaigns?.docs?.length)
      changeCampaignID({
        target: {
          value: ParamsCampaignId,
        },
      });
    else if (campaignID !== 'empty' && contactID === null) {
      navigate({
        pathname: window.location.pathname,
        search: `?conversationId=${campaignID}`,
      });
    }
  }, [ParamsCampaignId, changeCampaignID, campaigns, navigate, contactID, campaignID]);

  useEffectSkipFirst(() => {
    changeContactID(null);
  }, [ParamsCampaignId, changeContactID]);

  if (!campaignOptions) {
    return null;
  }

  return (
    <FormControl sx={sx} fullWidth size="small">
      <Autocomplete
        id="campaigns"
        loading={loading}
        renderInput={(params: any) => (
          <TextField {...params} label="Conversations" variant="outlined" />
        )}
        options={campaignOptions?.length ? campaignOptions : []}
        onChange={(e, value) => {
          changeCampaignID({
            target: {
              value: value.value,
            },
          });
          navigate({
            pathname: window.location.pathname,
            search: `?conversationId=${value.value}`,
          });
        }}
        disableClearable
        value={campaignOptions?.find((option: any) => option.value === ParamsCampaignId) || null}
        renderOption={(props, option: any) => {
          const assistance = data?.find((single: any) => single.campaignId === option.value);

          return (
            <li
              {...props}
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                gap: 10,
              }}
            >
              <Typography variant="body1" sx={{ fontSize: 15 }}>
                {option.label}
              </Typography>
              {assistance && assistance.unReadHumanAssistanceCount > 0 && (

                <Chip
                  size="small"
                  sx={{
                    fontSize: 10,
                    color: 'white',
                    backgroundColor: 'black',
                  }}
                  label={assistance.unReadHumanAssistanceCount}

                />
              )}
            </li>
          );
        }}
      />
    </FormControl>
  );
};

export default CampaignSelector;
