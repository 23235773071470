import { useState } from 'react';
import {
  Container,
  FormControl,
  MenuItem,
  Select,
  Stack,
  Typography,
  Grid,
  InputLabel,
  CircularProgress,
  Box,
  useTheme,
  Collapse,
  Divider,
} from '@mui/material';
import { doc, setDoc } from 'firebase/firestore';
import { Link } from 'react-router-dom';
import { DB } from 'src/auth/FirebaseContext';
import { useChat } from 'src/context/ChatContext';
import useCampaignParameters from 'src/hooks/useCampaignParameters';
import { PATH_DASHBOARD } from 'src/routes/paths';
import { useAuthContext } from 'src/auth/useAuthContext';
import useContactParameters from 'src/hooks/useContactParameters';
import useCampaignContacts from 'src/hooks/useCampaignContacts';
import ChatContactDetails from './ChatContactDetails';
import ChatCollapseButton from './ChatCollapseButton';
// eslint-disable-next-line import/no-cycle
import ContactPrivateFilter from './ContactPrivateFilter';
import ChatNotes from './ChatNotes';

export enum CHANNEL_TYPES {
  UI = 'UI',
}

const ChatFilterForm = () => {
  const { parameters, campaignID, contactID } = useChat();
  const [parametersList, setParametersList] = useState(false);
  const campaignParameter: any = useCampaignParameters(campaignID);
  const contactParameters: any = useContactParameters(campaignID, contactID);

  const {
    data: contactData,
    loading: contactDataLoading,
    fetchContact,
  } = useCampaignContacts(campaignID || '', contactID || '');

  const { user } = useAuthContext();

  const theme = useTheme();

  const handleUpdate = async (e: any, item: any) => {
    if (!contactID) {
      return;
    }

    const obj = {
      value: e.target.value,
      updatedBy: user?.uid,
      channel: CHANNEL_TYPES.UI,
    };

    const docRef = doc(
      DB as any,
      `campaigns/${campaignID}/contacts/${contactID}/parameters/${item?.name
        .toLowerCase()
        .replace(' ', '_')}`
    );

    await setDoc(docRef, obj);
  };

  if (!contactData) {
    return <div />;
  }

  // eslint-disable-next-line consistent-return
  return (
    <Container
      sx={{
        borderRadius: '0 10px 10px 0',
        height: '100%',
        width: '100%',
        border: 0,
        marginBottom: '25px',
        p: '0px !important',
      }}
    >
      {!contactID ? (
        <Typography
          display="flex"
          justifyContent="center"
          alignItems="center"
          variant="h6"
          height="100%"
          color={theme.palette.primary.main}
        >
          Select Chat to load Parameters!
        </Typography>
      ) : (
        <Stack sx={{}}>
          {campaignParameter.data && campaignParameter?.data?.length > 0 ? (
            <>
              {contactDataLoading ? (
                <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                  <CircularProgress />
                </Box>
              ) : (
                <>
                  <ChatContactDetails contactData={contactData} />
                  <ChatCollapseButton
                    isCollapse={parametersList}
                    onCollapse={() => setParametersList(!parametersList)}
                  >
                    Data Collected
                  </ChatCollapseButton>
                  <Collapse in={parametersList}>
                    <ContactPrivateFilter
                      campaignParameter={campaignParameter}
                      contactData={contactData}
                      fetchContact={fetchContact}
                    />
                    {campaignParameter?.data
                      ?.filter(
                        (item: any) => item.name !== 'humanAssistance' && item.name !== 'completed'
                      )
                      ?.map((single: any, index: any) => {
                        const value = contactParameters.data.filter(
                          (item: any) => item.id === single.id
                        );

                        if (value[0]?.value && !single.values.includes(value[0]?.value)) {
                          single.values.push(value[0]?.value);
                        }

                        return (
                          <Grid
                            container
                            spacing={2}
                            key={index}
                            marginTop={1}
                            justifyContent={'center'}
                            sx={{ my: 1 }}
                          >
                            <Grid item xs={10} md={10} lg={10}>
                              <FormControl fullWidth>
                                <InputLabel id="demo-simple-select-label">
                                  {single?.name}
                                </InputLabel>
                                <Select
                                  labelId="demo-simple-select-label"
                                  id="demo-simple-select"
                                  value={`${parameters?.[single.id]?.value}`}
                                  label={single?.name}
                                  onChange={(e: any) => handleUpdate(e, single)}
                                >
                                  {single?.values?.map((item: any, key: any) => (
                                    <MenuItem key={key} value={item}>
                                      {item}
                                    </MenuItem>
                                  ))}
                                </Select>
                              </FormControl>
                            </Grid>
                          </Grid>
                        );
                      })}
                  </Collapse>
                  <Divider />
                  <ChatNotes />
                </>
              )}
            </>
          ) : (
            <>
              <Typography
                display="flex"
                justifyContent="center"
                alignItems="center"
                variant="h6"
                height="100%"
                sx={{ mt: '20px' }}
              >
                No Parameters Found
              </Typography>
              <Stack alignItems="center" sx={{ fontSize: '13px' }}>
                <Link to={PATH_DASHBOARD.parametersetup} color="inherit">
                  Create Parameters
                </Link>
              </Stack>
            </>
          )}
        </Stack>
      )}
    </Container>
  );
};

export default ChatFilterForm;
