import { useQuery } from '@tanstack/react-query';
import { GeneralType } from 'src/utils/types';
import FirebaseService from '../services/firebaseService';
import { queryKeys } from '../utils/queryKeys';

const useIgnoredContacts = () =>
  useQuery<GeneralType[]>({
    queryKey: [queryKeys.ignoredContacts],
    queryFn: () => new FirebaseService().getIgnoredContacts(),
  });

export default useIgnoredContacts;
