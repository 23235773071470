export enum queryKeys {
  campaigns = 'campaigns',
  channels = 'channels',
  notes = 'notes',
  users = 'users',
  ignoredContacts = 'ignoredContacts',
  notifications = 'notifications',
  report = 'report',
  singleCampaign = 'singleCampaign',
  substitutionParameters = 'substitutionParameters',
  faq = 'faq',
  singleUser = 'singleUser',
}
