import { yupResolver } from '@hookform/resolvers/yup';
import { LoadingButton } from '@mui/lab';
import {
  Dialog,
  DialogContent,
  DialogTitle,
  Box,
  IconButton,
  Divider,
  MenuItem,
} from '@mui/material';
import { doc, updateDoc } from 'firebase/firestore';
import { useSnackbar } from 'notistack';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import * as Yup from 'yup';
import { DB } from 'src/auth/FirebaseContext';
import { RHFSelect, RHFTextField } from 'src/components/hook-form';
import FormProvider from 'src/components/hook-form/FormProvider';
import Iconify from 'src/components/iconify/Iconify';
import { useQueryClient } from '@tanstack/react-query';
import { queryKeys } from 'src/utils/queryKeys';

type FormValuesProps = {
  id: string;
  name: string;
  service: string;
  templateId: string;
  serviceId: string;
};

interface ChannelEditModalProps {
  openEditModal: boolean;
  handleCloseEditModal: () => void;
  selectedChannelData: any;
}

export default function ChannelEditModal({
  openEditModal,
  handleCloseEditModal,
  selectedChannelData,
}: ChannelEditModalProps) {
  const [isChannelLoading, setIsChannelLoading] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const queryClient = useQueryClient();

  const channelSchema = Yup.object().shape({
    name: Yup.string().required('Name is required'),
    service: Yup.string().required('Service is required'),
    templateId: Yup.string().required('Template Id is required'),
    serviceId: Yup.string().required('Service Id is required'),
  });

  const defaultValues = {
    name: '',
    service: '',
    templateId: '',
    serviceId: '',
  };

  const methods = useForm<FormValuesProps>({
    resolver: yupResolver(channelSchema),
    defaultValues,
  });

  const { reset, handleSubmit, watch } = methods;

  useEffect(() => {
    reset({
      name: selectedChannelData?.name || '',
      service: selectedChannelData?.service || '',
      templateId: selectedChannelData?.templateId || '',
      serviceId: selectedChannelData?.serviceId || '',
      id: selectedChannelData?.id || '',
    });
  }, [reset, selectedChannelData]);

  const onSubmit = async (data: FormValuesProps) => {
    const newdata: FormValuesProps = {
      name: data?.name,
      service: data?.service,
      templateId: data?.templateId,
      serviceId: data?.serviceId,
      id: data?.id,
    };
    try {
      setIsChannelLoading(true);
      const docRef = doc(DB, `channels`, selectedChannelData?.id);
      await updateDoc(docRef, newdata);
      queryClient.invalidateQueries({ queryKey: [queryKeys.channels] });
      enqueueSnackbar('Channel Update Successful', { variant: 'success' });
    } catch (error) {
      enqueueSnackbar('Channel Update Failed', { variant: 'error' });
    } finally {
      handleCloseEditModal();
      setIsChannelLoading(false);
    }
  };

  return (
    <Dialog open={openEditModal} onClose={handleCloseEditModal}>
      <DialogTitle
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          pt: 1.4,
          pr: 0.3,
        }}
      >
        Edit Channel
        <IconButton onClick={handleCloseEditModal}>
          <Iconify icon="radix-icons:cross-2" />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
          <RHFTextField name="name" label="Name" sx={{ mt: '10px' }} />
          <RHFSelect
            name="service"
            label="Channel Type"
            InputLabelProps={{ shrink: true }}
            sx={{ mt: '10px' }}
          >
            <MenuItem value="" sx={{ fontStyle: 'italic', color: 'text.secondary' }}>
              None
            </MenuItem>
            <Divider sx={{ borderStyle: 'dashed' }} />
            <MenuItem value="SMS">SMS</MenuItem>
            <MenuItem value="EMAIL">EMAIL</MenuItem>
          </RHFSelect>
          <RHFTextField
            name="templateId"
            label={watch('service').trim().toLowerCase() === 'email' ? 'Template Id' : 'AI Prompt'}
            sx={{ mt: '10px' }}
          />
          <RHFTextField
            name="serviceId"
            label={
              watch('service').trim().toLowerCase() === 'email'
                ? 'Sender Email'
                : 'Twilio Message Service'
            }
            sx={{ mt: '10px' }}
          />
          <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
            <LoadingButton
              variant="contained"
              loading={isChannelLoading}
              type="submit"
              sx={{ my: 3 }}
            >
              Save
            </LoadingButton>
          </Box>
        </FormProvider>
      </DialogContent>
    </Dialog>
  );
}
