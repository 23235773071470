import { useQuery } from '@tanstack/react-query';
import { GeneralType } from 'src/utils/types';
import FirebaseService from '../services/firebaseService';
import { queryKeys } from '../utils/queryKeys';

const useGetNotes = (campaignID: string, contactID: string) =>
  useQuery<GeneralType[]>({
    queryKey: [queryKeys.notes, campaignID, contactID],
    queryFn: () => new FirebaseService().getNotes(campaignID, contactID),
  });

export default useGetNotes;
