import { CircularProgress, Stack, Typography, useTheme } from '@mui/material';
import NotificationForm from 'src/sections/notifications/NotificationForm';
import useNotifications from 'src/hooks/useNotifications';
import { useChat } from 'src/context/ChatContext';
import CampaignSelector from './CampaignSelector';

function NotificationSetup() {
  const theme = useTheme();
  const { campaignID } = useChat();

  const { data: fetchedNotifications, isLoading: loading } = useNotifications(campaignID);

  return (
    <Stack
      sx={{
        width: '100%',
        height: '100%',
        px: 2,
        overflow: 'auto',
        '::-webkit-scrollbar': {
          display: 'none',
        },
        mt: -2,
      }}
    >
      <Stack direction="row" justifyContent="space-between" alignItems="center" sx={{ mt: 2 }}>
        <CampaignSelector sx={{ width: '40%' }} />
      </Stack>
      <Stack sx={{ padding: 2, marginTop: 2, height: '100%' }}>
        {loading ? (
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              height: '100%',
              width: '100%',
            }}
          >
            <CircularProgress />
          </div>
        ) : (
          <>
            {campaignID !== 'empty' ? (
              <NotificationForm
                campaignId={campaignID}
                items={fetchedNotifications?.sort(
                  (a: any, b: any) => a.dateAndTime - b.dateAndTime
                )}
              />
            ) : (
              <Typography
                display="flex"
                justifyContent="center"
                alignItems="center"
                variant="h6"
                height="100%"
                color={theme.palette.primary.main}
              >
                Please Select a conversation to move forward
              </Typography>
            )}
          </>
        )}
      </Stack>
    </Stack>
  );
}

export default NotificationSetup;
