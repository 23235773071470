// @mui
import { Alert, Container, Stack, Typography } from '@mui/material';
import { PATH_AUTH } from 'src/routes/paths';
import { LoadingButton } from '@mui/lab';
import { useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import FormProvider, { RHFTextField } from 'src/components/hook-form';
import { initiatePhoneNumberVerification } from 'src/utils/FirestoreUtil';
import { User, multiFactor } from 'firebase/auth';
import { useNavigate } from 'react-router-dom';
import { useAuthContext } from 'src/auth/useAuthContext';
import { auth } from 'src/auth/FirebaseContext';
import { PhoneIcon } from 'src/assets/icons';

type FormValuesProps = {
  phoneNumber: string;
};

const VerifyCodeSchema = Yup.object().shape({
  phoneNumber: Yup.string().required('Code is required'),
});

export default function GetPhoneNumber() {
  const defaultValues = {
    phoneNumber: '',
  };

  const [error, setError] = useState<string | undefined>(undefined);
  const [loading, setLoading] = useState(false);
  const recaptchaRef = useRef();

  const navigate = useNavigate();

  const { user } = useAuthContext();

  const methods = useForm({
    mode: 'onChange',
    resolver: yupResolver(VerifyCodeSchema),
    defaultValues,
  });

  const {
    formState: { errors },
    getValues,
  } = methods;

  const onSubmit = async (data: FormValuesProps) => {
    setLoading(true);

    // Check if the phone is valid
    if (!data.phoneNumber) {
      setError('Please enter your phone number');
      setLoading(false);
      return;
    }
    try {
      multiFactor(user as User)
        .getSession()
        .then((multiFactorSession) =>
          initiatePhoneNumberVerification(data?.phoneNumber, multiFactorSession)
        )
        .then((verificationId) => {
          navigate(PATH_AUTH.verifyOTP);
          window.verificationId = verificationId;
        })
        .catch((err) => {
          window.recaptchaVerifier.clear();
          // @ts-ignore
          recaptchaRef.current.innerHTML = '<div id="recaptcha-container"></div>';
          if (err.code === 'auth/requires-recent-login') {
            auth.signOut();
            navigate(PATH_AUTH.login);
          }
          if (err.code === 'auth/invalid-phone-number') {
            setError('Invalid Phone number.');
            setLoading(false);
            return;
          }
          setLoading(false);
          setError('Something went wrong.');
        });
    } catch (err: any) {
      setError(err.message);
      setLoading(false);
    }
  };
  return (
    <Container component="main">
      <Stack
        sx={{
          m: 'auto',
          maxWidth: 400,
          minHeight: '100vh',
          textAlign: 'center',
          justifyContent: 'center',
        }}
      >
        <PhoneIcon sx={{ mb: 5, height: 96 }} />
        <Typography variant="h3" paragraph>
          Please enter your phone number
        </Typography>
        <Typography sx={{ color: 'text.secondary', mb: 5 }}>
          We need your phone number to send you a verification code for security reasons.
        </Typography>
        <FormProvider methods={methods}>
          <Stack spacing={3}>
            <RHFTextField name="phoneNumber" label="Phone Number" />
            {(errors.phoneNumber || error) && (
              <Alert style={{ marginBottom: '20px' }} severity="error">
                {errors.phoneNumber?.message || error}
              </Alert>
            )}
            <LoadingButton
              fullWidth
              size="large"
              onClick={() => {
                onSubmit(getValues());
              }}
              variant="contained"
              loading={loading}
              sx={{ mt: 3 }}
            >
              Submit
            </LoadingButton>
          </Stack>
        </FormProvider>
      </Stack>
      <div id="recaptcha-container-parent" ref={recaptchaRef as any}>
        <div id="recaptcha-container" />
      </div>
    </Container>
  );
}
