/* eslint-disable @typescript-eslint/no-unused-vars */
import { Box, Button, Typography } from '@mui/material';
import { useState } from 'react';
import CreateFaq from './CreateFaq';
import FaqTable from './FaqTable';

export default function FaqSetup() {
  const [addFaqModalOpen, setAddFaqModalOpen] = useState(false);

  return (
    <>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <Typography variant="h4">FAQ</Typography>
        <Button
          size="medium"
          variant="contained"
          sx={{ mr: 3 }}
          onClick={() => setAddFaqModalOpen(true)}
        >
          Add FAQ
        </Button>
      </Box>
      <Box sx={{ mt: 3 }}>
        <FaqTable />
      </Box>
      <CreateFaq
        addFaqModalOpen={addFaqModalOpen}
        handleModalClose={() => setAddFaqModalOpen(false)}
      />
    </>
  );
}
