// @mui
import { List, Stack } from '@mui/material';
// locales
import Iconify from 'src/components/iconify/Iconify';
import useReport from 'src/hooks/useReport';
import { useLocales } from '../../../locales';
//
import { NavSectionProps } from '../types';
import { StyledSubheader } from './styles';
import NavList from './NavList';
import NavItem from './NavItem';

// ----------------------------------------------------------------------

export default function NavSectionVertical({ data, sx, ...other }: NavSectionProps) {
  const { translate } = useLocales();
  const { data: ReportLink } = useReport();

  return (
    <Stack sx={sx} {...other}>
      {data.map((group) => {
        const key = group.subheader || group.items[0].title;

        return (
          <List key={key} disablePadding sx={{ px: 2 }}>
            {group.subheader && (
              <StyledSubheader disableSticky>{`${translate(group.subheader)}`}</StyledSubheader>
            )}

            {group.items.map((list) => (
              <NavList
                key={list.title + list.path}
                data={list}
                depth={1}
                hasChild={!!list.children}
              />
            ))}

            <NavItem
              item={{
                title: 'Report',
                path: ReportLink || '',
                icon: <Iconify icon="carbon:report" />,
              }}
              depth={1}
              isExternalLink
            />
          </List>
        );
      })}
    </Stack>
  );
}
