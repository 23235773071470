/* eslint-disable no-nested-ternary */
import {
  FormControl,
  MenuItem,
  Select,
  Grid,
  InputLabel,
  Tooltip,
  IconButton,
} from '@mui/material';
import { doc, updateDoc } from 'firebase/firestore';
import { DB } from 'src/auth/FirebaseContext';
import { useAuthContext } from 'src/auth/useAuthContext';
import { useChat } from 'src/context/ChatContext';
import Iconify from 'src/components/iconify';
// eslint-disable-next-line import/no-cycle
import { CHANNEL_TYPES } from './ChatFilterForm';

interface ContactPrivateFilterProps {
  campaignParameter: any;
  contactData: any;
  fetchContact: VoidFunction;
  includeShowUsers?: boolean;
}

const ContactPrivateFilter = ({
  campaignParameter,
  contactData,
  fetchContact,
  includeShowUsers,
}: ContactPrivateFilterProps) => {
  const { user } = useAuthContext();
  const { campaignID, contactID } = useChat();
  return campaignParameter?.data
    ?.filter((item: any) => {
      if (includeShowUsers) {
        return (item.name === 'humanAssistance' || item.name === 'completed') && item?.showUsers;
      }
      return item.name === 'humanAssistance' || item.name === 'completed';
    })

    ?.map((single: any, index: any) => (
      <Grid
        container
        spacing={2}
        key={index}
        marginTop={1}
        justifyContent={'center'}
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Grid item xs={10} md={10} lg={10}>
          <FormControl fullWidth>
            <InputLabel id="demo-simple-select-label">{single?.name}</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={
                single.name === 'humanAssistance'
                  ? String(contactData?.humanAssistance)
                  : single.name === 'completed'
                  ? String(contactData?.completed)
                  : null
              }
              label={single?.name}
              onChange={async (e: any) => {
                const obj = {
                  [single.name]: e.target.value,
                  updatedBy: user?.uid,
                  channel: CHANNEL_TYPES.UI,
                };
                const docRef = doc(DB as any, `campaigns/${campaignID}/contacts/${contactID}`);
                await updateDoc(docRef, obj);
                fetchContact();
              }}
            >
              {single?.values?.map((item: any, key: any) => (
                <MenuItem key={key} value={item}>
                  {item}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        {includeShowUsers && (
          <Grid item xs={1} md={1} lg={1} display="flex" alignItems="center">
            <Tooltip title={single.userDescription || 'No description available'}>
              <IconButton>
                <Iconify icon="flat-color-icons:info" />
              </IconButton>
            </Tooltip>
          </Grid>
        )}
      </Grid>
    ));
};

export default ContactPrivateFilter;
