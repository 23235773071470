import { yupResolver } from '@hookform/resolvers/yup';
import { LoadingButton } from '@mui/lab';
import { Box, Dialog, DialogContent, DialogTitle, Grid, IconButton } from '@mui/material';
import { doc, updateDoc } from 'firebase/firestore';
import { useSnackbar } from 'notistack';
import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import * as Yup from 'yup';
import { DB } from 'src/auth/FirebaseContext';
import { RHFTextField } from 'src/components/hook-form';
import FormProvider from 'src/components/hook-form/FormProvider';
import Iconify from 'src/components/iconify/Iconify';
import { useQueryClient } from '@tanstack/react-query';
import { queryKeys } from 'src/utils/queryKeys';
import useFaqs from 'src/hooks/useFaqs';
import { useAuthContext } from 'src/auth/useAuthContext';

type FormValuesProps = {
  question: string;
  answer: string;
  title: string;
  url: string;
};

interface EditFaqProps {
  FaqModalOpen: boolean;
  handleModalClose: VoidFunction;
  selectedFaq: string;
  setSelectedFaq: Dispatch<SetStateAction<string>>;
}

export default function EditUserModal({
  FaqModalOpen,
  handleModalClose,
  selectedFaq,
  setSelectedFaq,
}: EditFaqProps) {
  const { enqueueSnackbar } = useSnackbar();
  const queryClient = useQueryClient();
  const { data } = useFaqs();
  const { profile } = useAuthContext();

  const [isFaqLoading, setIsFaqLoading] = useState(false);

  const FaqDocs = data?.filter((item: { id: string }) => item.id === selectedFaq)?.[0];

  const faqSchema = Yup.object().shape({
    question: Yup.string().required('Qustion is required'),
    answer: Yup.string().required('Answer is required.'),
    title: Yup.string().required('Title is required'),
    url: Yup.string().required('URL is required.'),
  });

  const defaultValues = {
    question: '',
    answer: '',
    url: '',
    title: '',
  };

  const methods = useForm<FormValuesProps>({
    resolver: yupResolver(faqSchema),
    defaultValues,
  });

  const { reset, handleSubmit } = methods;
  const onSubmit = async (formData: FormValuesProps) => {
    setIsFaqLoading(true);
    try {
      const docRef = doc(DB, 'faq', selectedFaq);
      await updateDoc(docRef, {
        title: formData.title,
        question: formData.question,
        answer: formData.answer,
        url: formData.url,
        updatedAt: new Date(),
        updatedBy: profile?.uid,
      });
      queryClient.invalidateQueries({ queryKey: [queryKeys.faq] });
      enqueueSnackbar('Faq Edited Successfully', { variant: 'success' });
    } catch (error) {
      enqueueSnackbar(error.response.data || 'Operation Failed', { variant: 'error' });
    } finally {
      handleModalClose();
      setSelectedFaq('');
      setIsFaqLoading(false);
      reset();
    }
  };

  const resetModalData = () => {
    handleModalClose();
    reset();
  };

  useEffect(() => {
    methods.setValue('question', FaqDocs?.question);
    methods.setValue('answer', FaqDocs?.answer);
    methods.setValue('title', FaqDocs?.title);
    methods.setValue('url', FaqDocs?.url);
  }, [FaqDocs, methods]);

  return (
    <Dialog open={FaqModalOpen} onClose={resetModalData}>
      <DialogTitle
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          pt: 1.4,
          pr: 0.3,
          minWidth: '500px !important',
        }}
      >
        Edit Faq
        <IconButton onClick={resetModalData}>
          <Iconify icon="radix-icons:cross-2" />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
          <Grid container justifyContent="space-between">
            <Grid item md={12}>
              <RHFTextField name="title" label="Title" sx={{ mt: '10px' }} />
            </Grid>
          </Grid>
          <Grid container justifyContent="space-between">
            <Grid item md={12}>
              <RHFTextField name="question" label="Question" sx={{ mt: '10px' }} />
            </Grid>
          </Grid>
          <Grid container justifyContent="space-between">
            <Grid item md={12}>
              <RHFTextField name="answer" label="Answer" sx={{ mt: '10px' }} />
            </Grid>
          </Grid>
          <Grid container justifyContent="space-between">
            <Grid item md={12}>
              <RHFTextField name="url" label="URL" sx={{ mt: '10px' }} />
            </Grid>
          </Grid>

          <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
            <LoadingButton variant="contained" loading={isFaqLoading} type="submit" sx={{ my: 3 }}>
              Save
            </LoadingButton>
          </Box>
        </FormProvider>
      </DialogContent>
    </Dialog>
  );
}
