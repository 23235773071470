import { collection, query, where, onSnapshot } from 'firebase/firestore';
import { useEffect, useState } from 'react';
import { DB } from 'src/auth/FirebaseContext';

interface campaignOptionsType {
  [key: string]: any;
}

const useAllUnreadHumanAssistanceContacts = (campaignOptions: campaignOptionsType) => {
  const [data, setData] = useState<any>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<Error | null>(null);

  useEffect(() => {
    const unsubscribe = () => {
      setLoading(true);
      setError(null);

      const unsubscribes = campaignOptions?.map((single: any) => {
        const { value: campaignId, label: campaignName } = single;
        let booleanSize = 0;
        let stringSize = 0;

        const updateState = () => {
          const totalSize = booleanSize + stringSize;
          setData((prevData: any) => [
            ...prevData.filter((item: any) => item.campaignId !== campaignId),
            { campaignName, unReadHumanAssistanceCount: totalSize, campaignId },
          ]);
        };
        const contactsBooleanQuery = query(
          collection(DB, `campaigns/${campaignId}/contacts`),
          where('humanAssistance', '==', true),
          where('unRead', '==', true)
        );

        const contactsStringQuery = query(
          collection(DB, `campaigns/${campaignId}/contacts`),
          where('humanAssistance', '==', 'true'),
          where('unRead', '==', true)
        );

        const booleanUnsub = onSnapshot(contactsBooleanQuery, (booleanSnapshot) => {
          booleanSize = booleanSnapshot.size;
          updateState();
        });

        // String snapshot listener
        const stringUnsub = onSnapshot(contactsStringQuery, (stringSnapshot) => {
          stringSize = stringSnapshot.size;
          updateState();
        });

        return () => {
          booleanUnsub();
          stringUnsub();
        };
      });

      return () => {
        unsubscribes.forEach((unsub: any) => unsub());
        setLoading(false);
      };
    };

    unsubscribe();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(campaignOptions)]);

  return { data, loading, error };
};

export default useAllUnreadHumanAssistanceContacts;
