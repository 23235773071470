import React from 'react';
import { Stack } from '@mui/material';
import CampaignSelector from './CampaignSelector';
import SettingFields from './Settings/SettingFields';

function SettingSetup() {
  return (
    <>
      <Stack
        sx={{
          width: '100%',
          height: '100%',
          px: 2,
          overflow: 'auto',
          '::-webkit-scrollbar': {
            display: 'none',
          },
          mt: -2,
        }}
      >
        <Stack direction="row" justifyContent="space-between" alignItems="center" sx={{ mt: 2 }}>
          <CampaignSelector sx={{ width: '40%' }} />
        </Stack>
      </Stack>
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        sx={{ mt: 5, px: 2 }}
      >
        <SettingFields />
      </Stack>
    </>
  );
}

export default SettingSetup;
