import { LoadingButton } from '@mui/lab';
import { Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import React, { cloneElement, useState } from 'react';

interface ConfirmationModalProps {
  children: React.ReactElement;
  title: string;
  content: string;
  handleDelete: () => Promise<void> | void;
  loading?: boolean;
  options?: Record<string, any>;
}

export default function DeleteConfirmationModal({
  children,
  title,
  content,
  handleDelete,
  loading,
  options,
}: ConfirmationModalProps) {
  const [openDeletionModal, setOpenDeletionModal] = useState(false);
  const handleModalConfirmation = async () => {
    await handleDelete();
    setOpenDeletionModal(false);
  };
  return (
    <>
      <Dialog open={openDeletionModal} onClose={() => setOpenDeletionModal(false)}>
        <DialogTitle>{title}</DialogTitle>
        <DialogContent>{content}</DialogContent>
        <DialogActions>
          <LoadingButton
            loading={loading}
            variant="contained"
            onClick={handleModalConfirmation}
            {...options}
          >
            Confirm
          </LoadingButton>
        </DialogActions>
      </Dialog>
      {cloneElement(children, { onClick: () => setOpenDeletionModal(true) })}
    </>
  );
}
