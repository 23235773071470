/* eslint-disable no-nested-ternary */
import { Box, Button } from '@mui/material';
import { useChat } from 'src/context/ChatContext';
import CampaignSelector from './CampaignSelector';
import AddNewParameterModal from './AddNewParameterModal';
import ParametersTable from './parameters/ParametersTable';

function ParameterSetup() {
  const { campaignID } = useChat();

  return (
    <>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <CampaignSelector sx={{ width: '40%' }} />
        <AddNewParameterModal campaignId={campaignID}>
          <Button disabled={campaignID === 'empty'} variant="outlined">
            Add New
          </Button>
        </AddNewParameterModal>
      </Box>
      <ParametersTable />
    </>
  );
}

export default ParameterSetup;
