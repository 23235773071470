import { Typography } from '@mui/material';
import _ from 'lodash';
import { useFormContext, Controller } from 'react-hook-form';
import { Upload, UploadProps } from './upload/upload';

export const base64toBlob = ({
  b64Data,
  sliceSize = 512,
  forcedMimeType,
}: {
  b64Data: string;
  sliceSize?: number;
  forcedMimeType?: string;
}) => {
  const mimeType = forcedMimeType || b64Data.match(/[^:]\w+\/[\w-+\d.]+(?=;|,)/)![0];
  b64Data = b64Data.replace(/^data:.*,/, '');

  const byteCharacters = atob(b64Data);
  const byteArrays = [];

  for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
    const slice = byteCharacters.slice(offset, offset + sliceSize);

    const byteNumbers = new Array(slice.length);
    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.charCodeAt(i);
    }

    const byteArray = new Uint8Array(byteNumbers);
    byteArrays.push(byteArray);
  }

  const blob = new Blob(byteArrays, { type: mimeType });
  return blob;
};

export async function blobToBase64(file: any) {
  return new Promise((resolve: any, reject: any) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    // eslint-disable-next-line func-names
    reader.onloadend = function () {
      const base64data = reader.result;
      resolve(base64data);
    };
    // eslint-disable-next-line func-names
    reader.onerror = function (err) {
      reject(err);
    };
  });
}

interface AppUploadFilePropTypes extends UploadProps {
  name: string;
  label?: string;
}

const AppUploadFile = ({ name, label, ...rest }: AppUploadFilePropTypes) => {
  const {
    control,
    setValue,
    watch,
    formState: { errors },
  } = useFormContext();
  const value = watch(name);

  return (
    <>
      {label && (
        <Typography variant="subtitle1" gutterBottom sx={{ mt: 2 }}>
          {label}
        </Typography>
      )}
      <Controller
        name={name}
        control={control}
        render={({ field, fieldState: { error } }) => (
          <Upload
            {...rest}
            file={rest.multiple ? undefined : field.value}
            files={rest.multiple ? field.value : undefined}
            error={Boolean(error)}
            disabled={(rest.maxFiles && field.value?.length >= rest?.maxFiles) || false}
            onDrop={async (fileVal: any) => {
              const images = await Promise.all(
                fileVal.map(async (single: File) => ({
                  preview: single?.type?.includes('image') ? await blobToBase64(single) : undefined,
                  file: single,
                }))
              );

              if (rest.multiple) {
                if (rest.maxFiles && field.value.length >= rest?.maxFiles) {
                  return;
                }
                setValue(name, [...(field.value || []), ...images]);
              } else {
                setValue(name, images[0]);
              }
            }}
            onDelete={() => {
              if (rest.multiple) {
                setValue(name, []);
              } else {
                setValue(name, '');
              }
            }}
            onRemove={(e) => {
              const afterFilter = value.filter((file: any) => {
                if (file.file) {
                  return !_.isEqual(e, file.file);
                }
                return !_.isEqual(e, file);
              });
              setValue(name, afterFilter);
            }}
          />
        )}
      />
      {errors && (
        <Typography color="#FF5630" variant="caption" display="block" gutterBottom>
          {errors?.[name]?.message as string}
        </Typography>
      )}
    </>
  );
};

export default AppUploadFile;
