import { useState } from 'react';
import { Collapse, Divider, IconButton, Stack, Typography } from '@mui/material';
import { doc, updateDoc } from 'firebase/firestore';
import { DB } from 'src/auth/FirebaseContext';
import { useChat } from 'src/context/ChatContext';
import { COMPLETION_CHANNEL, CONTACTS_STATUS } from 'src/sections/chat/nav/types';
import Iconify from 'src/components/iconify';
import ChatCollapseButton from './ChatCollapseButton';

export default function ChatContactDetails({ contactData }: any) {
  const [openAttachments, setOpenAttachments] = useState(false);
  const { campaignID, contactID } = useChat();

  if (Object.keys(contactData).length === 0) return null;

  const handleUpdateCompletionStatus = async () => {
    const campaignRef = doc(DB as any, `campaigns/${campaignID}/contacts/${contactID}`);
    await updateDoc(campaignRef, {
      completionChannel: COMPLETION_CHANNEL.UI_CHANNEL,
      completed: CONTACTS_STATUS.COMPLETION_STATUS,
    });
  };

  return (
    <>
      <ChatCollapseButton
        isCollapse={openAttachments}
        onCollapse={() => setOpenAttachments(!openAttachments)}
      >
        Contact Information
      </ChatCollapseButton>
      <Divider />

      <Collapse in={openAttachments}>
        <Stack
          spacing={2}
          sx={{
            p: (theme) => theme.spacing(2, 2.5, 2.5, 2.5),
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          {[
            { icon: 'teenyicons:id-solid', value: contactData?.id },
            { icon: 'eva:email-fill', value: contactData?.email },
            { icon: 'mdi:account-student', value: contactData?.group },
            { icon: 'ic:baseline-person', value: contactData?.name },
            { icon: 'eva:phone-fill', value: contactData?.phone },
            {
              icon:
                contactData?.completed === true
                  ? 'fluent-mdl2:completed-solid'
                  : 'gridicons:cross-circle',
              value: 'Completed',
            },
            {
              icon: 'pajamas:status',
              value: `Completion Status: ${contactData?.completionChannel}`,
            },
          ].map((row, index) => (
            <div
              key={row.icon}
              style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}
            >
              <Stack direction="row" alignItems="center">
                <Iconify
                  icon={row.icon}
                  sx={{
                    mr: 1,
                    flexShrink: 0,
                    color: 'text.disabled',
                  }}
                />
                <Typography variant="body2" noWrap={index === 2}>
                  {row.value}
                </Typography>
              </Stack>
              {index === 5 && contactData?.completionChannel !== COMPLETION_CHANNEL.UI_CHANNEL && (
                <IconButton onClick={handleUpdateCompletionStatus}>
                  <Iconify
                    icon="charm:circle-tick"
                    sx={{
                      flexShrink: 0,
                      color: 'text.disabled',
                    }}
                  />
                </IconButton>
              )}
            </div>
          ))}
        </Stack>
      </Collapse>
    </>
  );
}
