import {
  Box,
  CircularProgress,
  Grid,
  IconButton,
  MenuItem,
  Tooltip,
  Typography,
  useTheme,
} from '@mui/material';
import { doc, setDoc, updateDoc } from 'firebase/firestore';
import { DB } from 'src/auth/FirebaseContext';
import useCampaignParameters from 'src/hooks/useCampaignParameters';
import useContactParameters from 'src/hooks/useContactParameters';
import { CHANNEL_TYPES } from 'src/sections/chatFilter/components/ChatFilterForm';
import useSingleCampaign from 'src/hooks/useSingleCampaign';
import Iconify from 'src/components/iconify';
import { useSnackbar } from 'notistack';
import FormProvider, { RHFSelect } from 'src/components/hook-form';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { LoadingButton } from '@mui/lab';
import { useState } from 'react';
import PermissionDenied from './PermissionDenied';

interface Props {
  campaignID?: string;
  contactID?: string;
}

function WebForm({ campaignID, contactID }: Props) {
  const [loading, setLoading] = useState(false);
  const theme = useTheme();
  const { enqueueSnackbar } = useSnackbar();
  const { data: campaignData } = useSingleCampaign(campaignID as string);
  const campaignParameter: any = useCampaignParameters(campaignID as string);
  const contactParameters: any = useContactParameters(campaignID as string, contactID as string);

  const parametersAvailable = Boolean(
    campaignParameter?.data?.filter((item: any) => item.showUsers).length
  );

  const webFormSchema = Yup.object().shape(
    campaignParameter?.data?.reduce((schema: any, item: any) => {
      if (item.showUsers) {
        if (item.required) {
          schema[item.id] = Yup.string().required(`${item.name} is required`);
        } else {
          schema[item.id] = Yup.string();
        }
      }
      return schema;
    }, {})
  );

  const defaultValues = campaignParameter?.data
    ?.filter((item: any) => item?.showUsers)
    .reduce((acc: any, single: any) => {
      acc[single?.id] =
        contactParameters?.data?.find((parameter: any) => parameter?.id === single?.id)?.value ||
        '';
      return acc;
    }, {});

  const methods = useForm({
    resolver: yupResolver(webFormSchema),
    defaultValues,
  });

  const {
    handleSubmit,
    formState: { isDirty },
    reset,
  } = methods;

  const onSubmit = async (data: any) => {
    if (!contactID) {
      return;
    }
    try {
      setLoading(true);
      const contactRef = doc(DB as any, `campaigns/${campaignID}/contacts/${contactID}`);

      // eslint-disable-next-line consistent-return
      const updates = Object.keys(data).map(async (key) => {
        const value = data[key];
        const parameter = campaignParameter?.data?.find((item: any) => item.id === key);
        if (parameter) {
          const obj = {
            value,
            updatedBy: '',
            channel: CHANNEL_TYPES.UI,
          };
          const docRef = doc(
            DB as any,
            `campaigns/${campaignID}/contacts/${contactID}/parameters/${parameter?.name
              .toLowerCase()
              .replace(' ', '_')}`
          );
          return setDoc(docRef, obj);
        }
      });
      await Promise.all(updates);
      updateDoc(contactRef, { completed: true });
      enqueueSnackbar('Parameters updated successfully', { variant: 'success' });
    } catch (err) {
      enqueueSnackbar('Error updating parameters', { variant: 'error' });
    } finally {
      setLoading(false);
      reset(data);
    }
  };

  if (!campaignID || !contactID) {
    return <PermissionDenied />;
  }
  if (campaignParameter.loading) {
    return (
      <Box
        sx={{ height: '100vh', display: 'flex', alignItems: 'center', justifyContent: 'center' }}
      >
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
      }}
    >
      <Box
        textAlign="center"
        my={2}
        alignItems={'center'}
        display={'flex'}
        flexDirection={'column'}
      >
        {campaignData?.logoURL && (
          <img
            src={campaignData.logoURL}
            alt="Campaign Logo"
            style={{
              maxWidth: '200px',
              maxHeight: '100px',
              objectFit: 'contain',
            }}
          />
        )}
        {campaignData?.webFormDescription && (
          <Typography variant="body1" mt={2}>
            {campaignData.webFormDescription}
          </Typography>
        )}
      </Box>
      <Box
        sx={{
          width: { md: '50%', xs: '100%' },
        }}
      >
        {!parametersAvailable ? (
          <Typography
            display="flex"
            justifyContent="center"
            alignItems="center"
            variant="h6"
            height="100%"
            color={theme.palette.primary.main}
          >
            NO PARAMETER CREATED YET
          </Typography>
        ) : (
          <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
            <Grid
              container
              spacing={2}
              marginTop={1}
              justifyContent={'center'}
              sx={{ my: 1, display: 'flex', alignItems: 'center' }}
            >
              {campaignParameter?.data
                ?.filter((item: any) => item?.showUsers)
                ?.map((single: any, index: any) => {
                  const value = contactParameters?.data?.filter(
                    (item: any) => item.id === single.id
                  );
                  if (value[0]?.value && !single.values.includes(value[0]?.value)) {
                    single.values.push(value[0]?.value);
                  }

                  return (
                    <>
                      <Grid item xs={10} md={10} lg={10} key={index}>
                        <Controller
                          name={single.id}
                          defaultValue={defaultValues[single.id] || ''}
                          render={({ field }) => (
                            <RHFSelect {...field} label={single?.name}>
                              {single?.values?.map((item: any, key: any) => (
                                <MenuItem key={key} value={item}>
                                  {item}
                                </MenuItem>
                              ))}
                            </RHFSelect>
                          )}
                        />
                      </Grid>
                      <Grid item xs={1} md={1} lg={1} display="flex" alignItems="center">
                        <Tooltip title={single.userDescription || 'No description available'}>
                          <IconButton>
                            <Iconify icon="flat-color-icons:info" />
                          </IconButton>
                        </Tooltip>
                      </Grid>
                    </>
                  );
                })}
              <LoadingButton
                loading={loading}
                variant="contained"
                type="submit"
                sx={{ my: 2 }}
                disabled={!isDirty}
              >
                Submit
              </LoadingButton>
            </Grid>
          </FormProvider>
        )}
      </Box>
    </Box>
  );
}

export default WebForm;
