import { useState } from 'react';
import {
  Stack,
  Typography,
  Grid,
  Box,
  useTheme,
  Collapse,
  Divider,
  IconButton,
} from '@mui/material';
import { addDoc, collection, deleteDoc, doc, updateDoc } from 'firebase/firestore';
import { DB } from 'src/auth/FirebaseContext';
import { useChat } from 'src/context/ChatContext';
import { useAuthContext } from 'src/auth/useAuthContext';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import FormProvider from 'src/components/hook-form/FormProvider';
import { Controller, useForm } from 'react-hook-form';
import useGetNotes from 'src/hooks/useGetNotes';
import TextArea from 'src/components/textArea/TextArea';
import { LoadingButton } from '@mui/lab';
import Iconify from 'src/components/iconify';
import { CustomAvatar } from 'src/components/custom-avatar';
import moment from 'moment';
import DeleteConfirmationModal from 'src/sections/chat/header/DeleteConfirmationModal';
import { useQueryClient } from '@tanstack/react-query';
import { queryKeys } from 'src/utils/queryKeys';
import ChatCollapseButton from './ChatCollapseButton';

function ChatNotes() {
  const { campaignID, contactID } = useChat();
  const [loading, setLoading] = useState(false);
  const [notesList, setNotesList] = useState(false);
  const [editNoteId, setEditNoteId] = useState<string | null>(null);
  const [noteText, setNoteText] = useState<string>('');
  const { data: notes } = useGetNotes(campaignID, contactID);
  const queryClient = useQueryClient();
  const [editLoading, setEditLoading] = useState({ id: '', loading: false });
  const [deleteLoading, setDeleteLoading] = useState({ id: '', loading: false });

  const [isEditing, setIsEditing] = useState(false);
  const sortedNotes = notes?.sort((a: any, b: any) => a.createdAt.seconds - b.createdAt.seconds);

  const { user, profile } = useAuthContext();

  const theme = useTheme();

  const defaultValues = { note: '' };

  const noteSchema = Yup.object().shape({
    note: Yup.string().required('Note is required'),
  });

  const methods = useForm<any>({
    resolver: yupResolver(noteSchema),
    defaultValues,
  });

  const { handleSubmit, reset, control } = methods;

  const handleAddNote = async (data: { note: string }) => {
    try {
      setLoading(true);
      const colRef = collection(DB as any, `campaigns/${campaignID}/contacts/${contactID}/notes`);
      await addDoc(colRef, {
        note: data?.note,
        uid: user?.uid,
        createdAt: new Date(),
        name: profile?.displayName,
      });
      queryClient.invalidateQueries({ queryKey: [queryKeys.notes] });
      reset();
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  const handleSaveNote = async (item: any) => {
    try {
      setEditLoading({ ...editLoading, loading: true });
      const noteRef = doc(
        DB as any,
        `campaigns/${campaignID}/contacts/${contactID}/notes/${item.id}`
      );
      await updateDoc(noteRef, { note: noteText });
      queryClient.invalidateQueries({ queryKey: [queryKeys.notes] });
      setEditNoteId(null);
      setNoteText('');
      setIsEditing(false);
      setEditLoading({ id: '', loading: false });
    } catch (error) {
      console.log(error);
    }
  };

  const handleDeleteNote = async (id: string) => {
    try {
      setEditLoading({ id: '', loading: false });
      setEditNoteId(null);
      setIsEditing(false);
      setNoteText('');

      setDeleteLoading({ ...deleteLoading, loading: true });
      const noteRef = doc(DB as any, `campaigns/${campaignID}/contacts/${contactID}/notes/${id}`);
      await deleteDoc(noteRef);
      queryClient.invalidateQueries({ queryKey: [queryKeys.notes] });
      setDeleteLoading({ id: '', loading: false });
    } catch (error) {
      console.log(error);
      setDeleteLoading({ id: '', loading: false });
    }
  };
  return (
    <>
      <ChatCollapseButton isCollapse={notesList} onCollapse={() => setNotesList(!notesList)}>
        Staff Notes
      </ChatCollapseButton>
      <Collapse in={notesList}>
        <Stack>
          {notes?.length ? (
            <Grid
              container
              sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <Grid item md={10} xs={10}>
                <Typography
                  variant="caption"
                  sx={{
                    textWrap: 'wrap',
                    fontWeight: 'bold',
                    color: theme.palette.primary.main,
                  }}
                >
                  You can only edit or delete the notes you created.
                </Typography>
              </Grid>
            </Grid>
          ) : (
            // eslint-disable-next-line react/jsx-no-useless-fragment
            <></>
          )}

          {notes?.length ? (
            sortedNotes?.map((item: any) => (
              <Box key={item.id}>
                <Grid
                  container
                  sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    my: 1,
                  }}
                >
                  <Grid item md={10} xs={10}>
                    <Stack flexGrow={1} direction="row" alignItems="start" spacing={2} py={2}>
                      <CustomAvatar sx={{ cursor: 'pointer' }} name={item?.name} />

                      <Box
                        sx={{
                          display: 'flex',
                          gap: 1,
                          flexDirection: 'column',
                        }}
                      >
                        <Box
                          sx={{
                            display: 'flex',
                            flexDirection: 'row',
                            gap: 1,
                            justifyContent: 'center',
                            alignItems: 'center',
                          }}
                        >
                          <Typography
                            variant="subtitle2"
                            sx={{ color: 'text.primary', cursor: 'pointer' }}
                          >
                            {item?.name}
                          </Typography>
                          <Typography variant="caption" sx={{ color: 'text.secondary' }}>
                            {moment(
                              new Date(
                                item.createdAt.seconds * 1000 + item.createdAt.nanoseconds / 1000000
                              )
                            ).format('D-MM-YYYY h:mm a')}
                          </Typography>
                        </Box>
                        <Box>
                          <Box>
                            {editNoteId === item.id ? (
                              <TextArea
                                defaultValue={item.note}
                                onChange={(e: any) => setNoteText(e.target.value)}
                                disabled={editNoteId !== item.id || item.uid !== user?.uid}
                              />
                            ) : (
                              <Typography variant="body2">{item.note}</Typography>
                            )}
                          </Box>
                          <Box
                            sx={{
                              display: 'flex',
                              flexDirection: 'row',
                              justifyContent: 'flex-start',
                            }}
                          >
                            {item?.uid === user?.uid && (
                              <>
                                <LoadingButton
                                  variant="text"
                                  loading={
                                    editLoading?.id === item?.id ? editLoading?.loading : false
                                  }
                                  sx={{
                                    fontWeight: 200,
                                    minWidth: 'fit-content',
                                    borderRadius: '50%',
                                  }}
                                  // @ts-ignore
                                  disabled={
                                    (isEditing && editNoteId !== item.id) ||
                                    (editNoteId && item?.note === noteText) ||
                                    (editNoteId && !noteText)
                                  }
                                  onClick={() => {
                                    setNoteText(item.note);
                                    setEditNoteId(item.id);
                                    setIsEditing(true);
                                    setEditLoading({ id: item?.id, loading: false });
                                    if (editNoteId) {
                                      handleSaveNote(item);
                                    }
                                  }}
                                >
                                  {editNoteId === item.id ? (
                                    <Iconify icon="material-symbols:save-outline" />
                                  ) : (
                                    <Iconify icon="bxs:edit" />
                                  )}
                                </LoadingButton>

                                <DeleteConfirmationModal
                                  title="Delete Note"
                                  content="Are you sure to want to delete this note?"
                                  handleDelete={() => {
                                    setDeleteLoading({ id: item?.id, loading: false });
                                    handleDeleteNote(item?.id);
                                  }}
                                  options={{
                                    loading:
                                      deleteLoading?.id === item?.id
                                        ? deleteLoading?.loading
                                        : false,
                                  }}
                                >
                                  <IconButton
                                    sx={{
                                      fontWeight: 200,
                                      minWidth: 'fit-content',
                                      borderRadius: '50%',
                                      color: 'error.main',
                                    }}
                                    disabled={isEditing && editNoteId !== item.id}
                                  >
                                    <Iconify icon="eva:trash-2-outline" />
                                  </IconButton>
                                </DeleteConfirmationModal>
                              </>
                            )}
                          </Box>
                        </Box>
                      </Box>
                    </Stack>
                  </Grid>
                </Grid>
                <Divider />
              </Box>
            ))
          ) : (
            <Grid
              container
              sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <Grid item md={10} xs={10}>
                <Typography
                  variant="caption"
                  sx={{
                    textWrap: 'wrap',
                    fontWeight: 'bold',
                    color: theme.palette.primary.main,
                  }}
                >
                  Notes Not Found. Kindly Add One.
                </Typography>
              </Grid>
            </Grid>
          )}
        </Stack>

        <Stack sx={{ my: 2 }}>
          <FormProvider methods={methods} onSubmit={handleSubmit(handleAddNote)}>
            <Controller
              name="note"
              control={control}
              render={({ field, formState: { errors } }) => (
                <Grid container justifyContent="center" alignItems="center">
                  <Grid item md={10}>
                    <TextArea {...field} />
                  </Grid>
                  <Grid item md={10} sx={{ display: 'flex', flexDirection: 'column' }}>
                    {errors.note && typeof errors.note.message === 'string' && (
                      <Typography variant="caption" color="error">
                        {errors?.note?.message}
                      </Typography>
                    )}
                    <Grid item md={2}>
                      <LoadingButton loading={loading} type="submit" variant="contained">
                        Add
                      </LoadingButton>
                    </Grid>
                  </Grid>
                </Grid>
              )}
            />
          </FormProvider>
        </Stack>
      </Collapse>
    </>
  );
}

export default ChatNotes;
