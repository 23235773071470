export const siteName = 'EDU Labs';
export const getCustomEntitiesUrl = '/api/getCustomEntities';
export const getAllIntentsUrl = '/api/getAllIntents';
export const updateIntentUrl = '/api/updateIntent';
export const createIntentUrl = '/api/createIntent';
export const updateCustomEntities = '/api/updateCustomEntities';
export const bulkDeleteContacts = '/api/bulkDeleteContacts';
export const inputContextNameUrl = 'locations/global/agent/sessions/-/contexts/';
export const createUserUrl = '/api/createNewUser';
export const cloneLogo = '/api/cloneLogo';
