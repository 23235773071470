import { useQuery } from '@tanstack/react-query';
import { GeneralType } from 'src/utils/types';
import FirebaseService from '../services/firebaseService';
import { queryKeys } from '../utils/queryKeys';

const useFaqs = () =>
  useQuery<GeneralType[]>({
    queryKey: [queryKeys.faq],
    queryFn: () => new FirebaseService().getFaq(),
  });

export default useFaqs;
