export type FormValuesProps = {
  group: string;
  email: string;
  name: string;
  phone: string;
  id: string;
};

export interface AddContactModalProps {
  contactModalOpen: boolean;
  handleModalClose: () => void;
}

export const CONTACTS_STATUS = {
  DEFAULT_STATUS: false,
  COMPLETION_STATUS: true,
};

export const COMPLETION_CHANNEL = {
  DEFAULT_CHANNEL: 'NULL',
  EMAIL_CHANNEL: 'EMAIL',
  SMS_CHANNEL: 'SMS',
  UI_CHANNEL: 'UI',
  EXTERNAL_CHANNEL: 'EXTERNAL',
};

export const defaultContactField = {
  error: 'false',
  humanAssistance: false,
  completed: CONTACTS_STATUS.DEFAULT_STATUS,
  updatedBy: 'system',
  completionChannel: COMPLETION_CHANNEL.DEFAULT_CHANNEL,
};
