import { useState } from 'react';
import { Box, Button, Stack, Typography } from '@mui/material';
import CampaignsTable from './Campaigns/CampaignsTable';
import AddCampaignModal from './Campaigns/AddCampaignModal';

function Campaigns() {
  const [campaignModalOpen, setCampaignModalOpen] = useState(false);

  return (
    <>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          px: 2.2,
        }}
      >
        <Typography variant="h5">Conversations</Typography>
        <Button variant="outlined" onClick={() => setCampaignModalOpen(true)}>
          Add New
        </Button>
      </Box>
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        sx={{ mt: 2, px: 2 }}
      >
        <CampaignsTable />
      </Stack>
      <AddCampaignModal
        campaignModalOpen={campaignModalOpen}
        handleModalClose={() => setCampaignModalOpen(false)}
      />
    </>
  );
}

export default Campaigns;
