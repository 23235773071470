import { Box, CircularProgress, TableCell, TableRow } from '@mui/material';

interface TableLoaderProps {
  headLabel: {
    id?: string;
    label?: string;
  }[];
}

export default function TableLoader({ headLabel }: TableLoaderProps) {
  return (
    <TableRow>
      <TableCell align="center" colSpan={headLabel.length}>
        <Box width="100%" display="flex" justifyContent="center">
          <CircularProgress size="22px" />
        </Box>
      </TableCell>
    </TableRow>
  );
}
