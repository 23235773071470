/* eslint-disable no-nested-ternary */
import moment from 'moment';
import { USER_STATUS, User_Role, allowedFileTypes } from './enums';

export const isUserAdmin = (role: any) => {
  if (role === User_Role.admin) {
    return true;
  }
  return false;
};

export const isUserActive = (status: any) => {
  if (status === USER_STATUS.active) {
    return true;
  }
  return false;
};

export const getFirebaseDateTime = (date: any, format?: string) => {
  const fm = format || 'dd MMM yyyy p';

  const momentDate = moment(new Date(date.seconds * 1000 + date.nanoseconds / 1e6));

  return momentDate.isValid() ? momentDate.format(fm) : '';
};

export const getFileType = (contentType: string) =>
  contentType === allowedFileTypes[4]
    ? 'docx'
    : contentType === allowedFileTypes[5]
    ? 'pptx'
    : contentType.split('/')[1];
