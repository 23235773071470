import { useQuery } from '@tanstack/react-query';
import { GeneralType } from 'src/utils/types';
import FirebaseService from '../services/firebaseService';
import { queryKeys } from '../utils/queryKeys';

export const useChannels = () =>
  useQuery<GeneralType[]>({
    queryKey: [queryKeys.channels],
    queryFn: () => new FirebaseService().getChannels(),
  });
