import { yupResolver } from '@hookform/resolvers/yup';
import { LoadingButton } from '@mui/lab';
import { Box, Card } from '@mui/material';
import { doc, updateDoc } from 'firebase/firestore';
import { useSnackbar } from 'notistack';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import * as Yup from 'yup';
import { DB } from 'src/auth/FirebaseContext';
import { RHFTextField } from 'src/components/hook-form';
import FormProvider from 'src/components/hook-form/FormProvider';
import { useChat } from 'src/context/ChatContext';

import { useSearchParams } from 'react-router-dom';
import useCampaigns from 'src/hooks/useCampaigns';

type FormValuesProps = {
  twilioServices: any;
  twilioNumber: any;
};

export default function SettingFields() {
  const [isLoading, setIsLoading] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const [searchParams] = useSearchParams();
  const { campaignID } = useChat();
  const { data: campaignsData } = useCampaigns();
  const ParamsSettingId = searchParams.get('conversationId') || campaignID;
  const preValues = campaignsData?.filter((val: any) => val.id === ParamsSettingId);

  const settingSchema = Yup.object().shape({
    twilioServices: Yup.string().required('Twilio Services is required'),
    twilioNumber: Yup.string().required('Twilio Number is required'),
  });

  const defaultValues = {
    twilioServices: '',
    twilioNumber: '',
  };

  const methods = useForm<FormValuesProps>({
    resolver: yupResolver(settingSchema),
    defaultValues,
  });

  const { handleSubmit, setValue } = methods;

  useEffect(() => {
    if (preValues) {
      setValue('twilioServices', preValues[0]?.twilioServices);
      setValue('twilioNumber', preValues[0]?.twilioNumber);
    }
  }, [preValues, setValue]);

  const onSubmit = async (data: FormValuesProps) => {
    const newdata: FormValuesProps = {
      twilioServices: data?.twilioServices,
      twilioNumber: data?.twilioNumber,
    };
    try {
      setIsLoading(true);
      const docRef = doc(DB, `campaigns/${campaignID}`);
      await updateDoc(docRef, newdata);
      enqueueSnackbar('Twilio Update Successful', { variant: 'success' });
    } catch (error) {
      enqueueSnackbar('Twilio Update Failed', { variant: 'error' });
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Card sx={{ width: '100%', p: 5 }}>
      <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
        <Box display="flex" alignItems="center" gap={5}>
          <RHFTextField name="twilioServices" label="SMS services" />
          <RHFTextField name="twilioNumber" label="SMS number" />
        </Box>
        <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
          <LoadingButton variant="contained" loading={isLoading} type="submit" sx={{ my: 3 }}>
            Submit
          </LoadingButton>
        </Box>
      </FormProvider>
    </Card>
  );
}
