import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  IconButton,
  Box,
  Typography,
} from '@mui/material';
import { useSnackbar } from 'notistack';
import { useState } from 'react';
import Iconify from 'src/components/iconify';

type GenerateCodeModalProps = {
  open: boolean;
  onClose: () => void;
  agentProjectId: string;
  agentId: string;
};

export default function GenerateCodeModal({
  open,
  onClose,
  agentId,
  agentProjectId,
}: GenerateCodeModalProps) {
  const { enqueueSnackbar } = useSnackbar();
  const [isCopied, setIsCopied] = useState(false);

  const codeToShow = `<link rel="stylesheet" href="https://www.gstatic.com/dialogflow-console/fast/df-messenger/prod/v1/themes/df-messenger-default.css">
  <script src="https://www.gstatic.com/dialogflow-console/fast/df-messenger/prod/v1/df-messenger.js"></script>
  <df-messenger
    project-id="${agentProjectId}"
    agent-id="${agentId}"
    language-code="en"
    max-query-length="-1">
    <df-messenger-chat-bubble
     chat-title="Towson University FinAid">
    </df-messenger-chat-bubble>
  </df-messenger>
  <style>
    df-messenger {
      z-index: 999;
      position: fixed;
      --df-messenger-font-color: #000;
      --df-messenger-font-family: Google Sans;
      --df-messenger-chat-background: #f3f6fc;
      --df-messenger-message-user-background: #d3e3fd;
      --df-messenger-message-bot-background: #fff;
      bottom: 16px;
      right: 16px;
    }
  </style>`;

  const handleCopyCode = () => {
    navigator.clipboard
      .writeText(codeToShow)
      .then(() => {
        setIsCopied(true);
        setTimeout(() => {
          setIsCopied(false);
        }, 2000);
      })

      .catch(() => {
        enqueueSnackbar('Failed to copy code', { variant: 'error' });
      });
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Add Nora to Your Website</DialogTitle>
      <DialogContent sx={{ overflow: 'hidden' }}>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            backgroundColor: '#2F2F2F',

            padding: '8px 16px',
            borderTopLeftRadius: '8px',
            borderTopRightRadius: '8px',
            borderBottom: '1px solid #ccc',
          }}
        >
          <Typography sx={{ color: 'white' }} variant="caption">
            Code Snippet
          </Typography>
          <IconButton onClick={handleCopyCode} size="small">
            {isCopied ? (
              <>
                <Iconify icon="material-symbols:check" width={20} height={20} color="white" />
                <Typography sx={{ color: 'white', ml: 1 }} variant="caption">
                  Copied
                </Typography>
              </>
            ) : (
              <>
                <Iconify
                  icon="material-symbols-light:content-copy-outline"
                  width={20}
                  height={20}
                  color="white"
                />
                <Typography sx={{ color: 'white', ml: 1 }} variant="caption">
                  Copy Code
                </Typography>
              </>
            )}
          </IconButton>
        </Box>

        <Box
          sx={{
            position: 'relative',
            backgroundColor: '#f5f5f5',
            padding: '16px',
            borderRadius: '0 0 8px 8px',
            overflow: 'scroll',
            fontFamily: 'monospace',
            whiteSpace: 'pre-wrap',
            wordWrap: 'break-word',
            border: '1px solid #ccc',
            maxHeight: '300px',
          }}
        >
          <Typography component="pre" variant="body2">
            {codeToShow}
          </Typography>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button variant="contained" onClick={onClose}>
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
}
