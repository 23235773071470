import { Container, Stack, Typography } from '@mui/material';
import { EmailInboxIcon } from 'src/assets/icons';
import AuthVerifyCodeForm from 'src/sections/auth/AuthVerifyCodeForm';

export default function VerifyCodePage() {
  return (
    <Container component="main">
      <Stack
        sx={{
          m: 'auto',
          maxWidth: 400,
          minHeight: '100vh',
          textAlign: 'center',
          justifyContent: 'center',
        }}
      >
        <EmailInboxIcon sx={{ mb: 5, height: 96 }} />

        <Typography variant="h3" paragraph>
          Please check phone inbox!
        </Typography>

        <Typography sx={{ color: 'text.secondary', mb: 5 }}>
          We have sent a 6-digit confirmation code to your phone, please enter the code in below box
          to verify your login.
        </Typography>

        <AuthVerifyCodeForm />
      </Stack>
    </Container>
  );
}
