// @mui
import { Link, Stack, Typography, useTheme } from '@mui/material';
// sections
import AuthResetPasswordForm from 'src/sections/auth/AuthResetPasswordForm';
import { siteName } from 'src/utils/siteVariables';
// routes
import { PATH_AUTH } from '../../routes/paths';
// layouts
import CompactLayout from '../../layouts/compact';

// ----------------------------------------------------------------------
// @ts-ignore
ResetPasswordPage.getLayout = (page: React.ReactElement) => <CompactLayout>{page}</CompactLayout>;

// ----------------------------------------------------------------------

export default function ResetPasswordPage() {
  const theme = useTheme();
  return (
    <>
      <head>
        <title> Reset Password | {siteName}</title>
      </head>

      <Stack p={2}>
        <img src="/logo/logo.png" alt="logo" width={100} />
      </Stack>
      <Stack sx={{ display: 'flex', flexDirection: 'center', alignItems: 'center' }}>
        <Stack
          alignItems="center"
          justifyContent="center"
          p={2}
          sx={{ display: 'flex', flexDirection: 'column', maxWidth: '420px' }}
        >
          <Stack spacing={3} alignItems="center" justifyContent="center">
            <Typography variant="h3" paragraph>
              Forgot your password?
            </Typography>

            <Typography sx={{ color: 'text.secondary', textAlign: 'center' }}>
              Please enter the email address associated with your account and We will email you a
              link to reset your password.
            </Typography>

            <AuthResetPasswordForm />

            <Link
              href={PATH_AUTH.login}
              color={theme.palette.primary.main}
              variant="subtitle2"
              sx={{
                mt: 3,
                mx: 'auto',
                alignItems: 'center',
                display: 'inline-flex',
                fontSize: 20,
              }}
            >
              Back
            </Link>
          </Stack>
        </Stack>
      </Stack>
    </>
  );
}
