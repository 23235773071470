// @mui
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import Tooltip from '@mui/material/Tooltip';
import TableBody from '@mui/material/TableBody';
import IconButton from '@mui/material/IconButton';
import TableContainer from '@mui/material/TableContainer';
import { tableCellClasses } from '@mui/material/TableCell';
// components
import Iconify from 'src/components/iconify';
import {
  emptyRows,
  TableProps,
  TableNoData,
  TableEmptyRows,
  TableHeadCustom,
  TableSelectedAction,
} from './table';
//
import FileManagerTableRow from './file-manager-table-row';

// ----------------------------------------------------------------------

const TABLE_HEAD = [
  { id: 'name', label: 'Name' },
  { id: 'size', label: 'Size', width: 120 },
  { id: 'type', label: 'Type', width: 120 },
  { id: 'createdAt', label: 'Created At', width: 140 },
  { id: '', width: 88 },
];

// ----------------------------------------------------------------------

type Props = {
  table: TableProps;
  tableData: any[];
  notFound: boolean;
  onOpenConfirm: VoidFunction;
  onDeleteRow: (id: string) => void;
};

export default function FileManagerTable({
  table,
  tableData,
  notFound,
  onDeleteRow,
  onOpenConfirm,
}: Props) {
  const theme = useTheme();

  const {
    dense,
    order,
    orderBy,
    selected,
    onSelectRow,
    onSelectAllRows,
    onSort,
  } = table;

  const denseHeight = dense ? 58 : 78;

  return (
    <Box
      sx={{
        position: 'relative',
        m: theme.spacing(-2, -3, -3, -3),
      }}
    >
      <TableSelectedAction
        dense={dense}
        numSelected={selected.length}
        rowCount={tableData.length}
        onSelectAllRows={(checked) =>
          onSelectAllRows(
            checked,
            tableData.map((row) => row.id)
          )
        }
        action={
          <Tooltip title="Delete">
            <IconButton color="primary" onClick={onOpenConfirm}>
              <Iconify icon="solar:trash-bin-trash-bold" />
            </IconButton>
          </Tooltip>
        }
        sx={{
          pl: 1,
          pr: 2,
          top: 16,
          left: 24,
          right: 24,
          width: 'auto',
          borderRadius: 1.5,
        }}
      />

      <TableContainer
        sx={{
          p: theme.spacing(0, 3, 3, 3),
        }}
      >
        <Table
          size={dense ? 'small' : 'medium'}
          sx={{
            borderCollapse: 'separate',
            borderSpacing: '0 16px',
          }}
        >
          <TableHeadCustom
            order={order}
            orderBy={orderBy}
            headLabel={TABLE_HEAD}
            rowCount={tableData.length}
            numSelected={selected.length}
            onSort={onSort}
            onSelectAllRows={(checked) =>
              onSelectAllRows(
                checked,
                tableData.map((row) => row.id)
              )
            }
            sx={{
              [`& .${tableCellClasses.head}`]: {
                '&:first-of-type': {
                  borderTopLeftRadius: 12,
                  borderBottomLeftRadius: 12,
                },
                '&:last-of-type': {
                  borderTopRightRadius: 12,
                  borderBottomRightRadius: 12,
                },
              },
            }}
          />

          <TableBody>
            {tableData.map((row) => (
              <FileManagerTableRow
                key={row.id}
                row={row}
                selected={selected.includes(row.id)}
                onSelectRow={() => onSelectRow(row.id)}
                onDeleteRow={() => onDeleteRow(row.id)}
              />
            ))}

            <TableEmptyRows
              height={denseHeight}
              emptyRows={emptyRows(0, tableData.length, tableData.length)}
            />

            <TableNoData
              notFound={notFound}
              sx={{
                m: -2,
                borderRadius: 1.5,
                border: `dashed 1px ${theme.palette.divider}`,
              }}
            />
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
}
