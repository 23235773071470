// @mui
import { Stack } from '@mui/material';
// components
import Logo from '../../components/logo';
import Image from '../../components/image';
//
import { StyledRoot, StyledSectionBg, StyledSection, StyledContent } from './styles';

// ----------------------------------------------------------------------

type Props = {
  title?: string;
  illustration?: string;
  children: React.ReactNode;
};

export default function LoginLayout({ children, illustration, title }: Props) {
  return (
    <StyledRoot>
      <Logo
        sx={{
          display: { xs: 'none', sm: 'block' },
          zIndex: 1,
          position: 'absolute',
          mt: { xs: 1.5, md: 1 },
          ml: { xs: 2, md: 5 },
        }}
      />

      <StyledSection sx={{ background: 'linear-gradient(to top, #ffcb57, #ffffff)' }}>
        {/* <Typography variant="h3" sx={{ mb: 7, mt: 2, maxWidth: 480, textAlign: 'center' }}>
          {title || 'Hi, Welcome back'}
        </Typography> */}

        <Image
          disabledEffect
          visibleByDefault
          alt="auth"
          src={illustration || '/assets/illustrations/dashboard.png'}
          sx={{ maxWidth: 720, position: 'absolute', bottom: 0 }}
        />

        <StyledSectionBg />
      </StyledSection>

      <StyledContent
        sx={{
          overflowY: 'scroll',
          pt: { xs: '0', sm: 10 },
        }}
      >
        <Stack sx={{ width: 1 }}>
          <Logo
            sx={{
              display: { xs: 'block', sm: 'none' },
            }}
          />
          {children}
        </Stack>
      </StyledContent>
    </StyledRoot>
  );
}
