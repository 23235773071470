/* eslint-disable no-nested-ternary */
import React, { useState } from 'react';
import {
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Paper,
  Box,
  IconButton,
  MenuItem,
  CircularProgress,
  Typography,
  useTheme,
  Tooltip,
} from '@mui/material';
import { TableVirtuoso, TableComponents } from 'react-virtuoso';
import Iconify from 'src/components/iconify/Iconify';
import MenuPopover from 'src/components/menu-popover/MenuPopover';
import { useChat } from 'src/context/ChatContext';
import useCampaignParameters from 'src/hooks/useCampaignParameters';
import { deleteDoc, doc } from 'firebase/firestore';
import { DB } from 'src/auth/FirebaseContext';
import DeleteConfirmationModal from 'src/sections/chat/header/DeleteConfirmationModal';
import AddNewParameterModal from '../AddNewParameterModal';

const TableBodyComponent: React.ComponentType<any> = React.forwardRef<HTMLTableSectionElement>(
  (props, ref) => <TableBody {...props} ref={ref} />
);

const ScrollerComponent: React.ComponentType<any> = React.forwardRef<HTMLDivElement>(
  (props, ref) => <TableContainer component={Paper} {...props} ref={ref} />
);

const VirtuosoTableComponents: TableComponents = {
  Scroller: ScrollerComponent,
  Table: (props) => <Table {...props} sx={{ borderCollapse: 'separate', tableLayout: 'fixed' }} />,
  TableHead: TableHead as React.ComponentType<any>,
  // eslint-disable-next-line react/prop-types
  TableRow: ({ item: _item, ...props }) => <TableRow {...props} />,
  TableBody: TableBodyComponent,
};

export default function ParametersTable() {
  const theme = useTheme();
  const [openPopover, setOpenPopover] = useState<HTMLElement | null>(null);
  const [selectedIndex, setSelectedIndex] = useState<number | null | any>(null);
  const { campaignID } = useChat();
  const { data, loading } = useCampaignParameters(campaignID);

  const handleOpenPopover = (event: React.MouseEvent<HTMLElement>, index: number): void => {
    setOpenPopover(event.currentTarget);
    setSelectedIndex(index);
  };

  const handleClosePopover = () => {
    setOpenPopover(null);
    setSelectedIndex(null);
  };

  const handleDelete = async (single: any) => {
    const docName = single?.name.toLowerCase().replace(' ', '_');
    await deleteDoc(doc(DB as any, 'campaigns', campaignID, 'parameterSetup', `${docName}`));
  };

  const headLabel = [
    { id: 'name', label: 'Name', width: '100px' },
    { id: 'values', label: 'Values', width: '100px' },
    { id: 'userDescription', label: 'User Description', width: '100px' },
    { id: 'showUsers', label: 'Show Users', width: '75px' },
    { id: 'required', label: 'Required', width: '75px' },
    { id: 'Action', label: 'Action', width: '10px' },
  ];

  return (
    <Box
      sx={{
        mt: 2,
        display: 'flex',
        flexDirection: 'center',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      {loading ? (
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            height: '100%',
            width: '100%',
          }}
        >
          <CircularProgress />
        </div>
      ) : campaignID !== 'empty' ? (
        data?.length !== 0 ? (
          <Box style={{ height: '75vh', width: '100%' }}>
            <TableVirtuoso
              data={data}
              components={VirtuosoTableComponents}
              fixedHeaderContent={() => (
                <TableRow>
                  {headLabel.map((head) => (
                    <TableCell
                      key={head.id}
                      align="left"
                      style={{ width: head?.width }}
                      sx={{
                        backgroundColor: 'background.paper',
                      }}
                    >
                      {head.label}
                    </TableCell>
                  ))}
                </TableRow>
              )}
              itemContent={(index) => (
                <>
                  <TableCell style={{ textOverflow: 'ellipsis', overflow: 'hidden' }} align="left">
                    {data[index]?.name}
                  </TableCell>
                  <TableCell style={{ textOverflow: 'ellipsis', overflow: 'hidden' }} align="left">
                    {data[index]?.values.join(', ')}
                  </TableCell>
                  <TableCell
                    style={{ textOverflow: 'ellipsis', overflow: 'hidden', whiteSpace: 'nowrap' }}
                    align="left"
                  >
                    <Tooltip title={data[index]?.userDescription} placement="top">
                      <span>{data[index]?.userDescription}</span>
                    </Tooltip>
                  </TableCell>
                  <TableCell
                    style={{ textOverflow: 'ellipsis', overflow: 'hidden' }}
                    align="center"
                  >
                    {data[index]?.showUsers ? (
                      <Iconify icon="hugeicons:tick-01" color="green" />
                    ) : (
                      <Iconify icon="charm:cross" color="red" />
                    )}
                  </TableCell>
                  <TableCell
                    style={{ textOverflow: 'ellipsis', overflow: 'hidden' }}
                    align="center"
                  >
                    {data[index]?.required ? (
                      <Iconify icon="hugeicons:tick-01" color="green" />
                    ) : (
                      <Iconify icon="charm:cross" color="red" />
                    )}
                  </TableCell>
                  <TableCell>
                    <IconButton
                      color={openPopover ? 'inherit' : 'default'}
                      onClick={(event) => handleOpenPopover(event, index)}
                    >
                      <Iconify icon="eva:more-vertical-fill" />
                    </IconButton>
                  </TableCell>
                </>
              )}
            />

            <MenuPopover
              open={openPopover}
              onClose={handleClosePopover}
              arrow="right-top"
              sx={{ width: 160 }}
            >
              <MenuItem
                divider
                disabled={['completed', 'humanAssistance'].includes(data[selectedIndex]?.name)}
              >
                <AddNewParameterModal
                  singleItem={data[selectedIndex]}
                  campaignId={campaignID}
                  afterSubmit={handleClosePopover}
                >
                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: 'row',
                      justifyContent: 'start',
                      alignItems: 'center',
                      width: '100%',
                    }}
                  >
                    <Iconify icon="bxs:edit" />
                    Edit
                  </Box>
                </AddNewParameterModal>
              </MenuItem>

              <MenuItem
                sx={{ color: 'error.main' }}
                disabled={['completed', 'humanAssistance'].includes(data[selectedIndex]?.name)}
              >
                {selectedIndex !== null && (
                  <DeleteConfirmationModal
                    title="Parameter Deletion"
                    content="Are you sure you want to delete this parameter?"
                    handleDelete={() => {
                      handleDelete(data[selectedIndex]);
                      handleClosePopover();
                    }}
                  >
                    <Box
                      sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'start',
                        alignItems: 'center',
                        width: '100%',
                      }}
                    >
                      <Iconify icon="eva:trash-2-outline" />
                      <Typography variant="body2">Delete</Typography>
                    </Box>
                  </DeleteConfirmationModal>
                )}
              </MenuItem>
            </MenuPopover>
          </Box>
        ) : (
          <Typography
            display="flex"
            justifyContent="center"
            alignItems="center"
            variant="h6"
            height="100%"
            color={theme.palette.primary.main}
          >
            NO PARAMETER CREATED YET
          </Typography>
        )
      ) : (
        <Typography
          display="flex"
          justifyContent="center"
          alignItems="center"
          variant="h6"
          height="100%"
          color={theme.palette.primary.main}
        >
          Please Select a conversation to move forward
        </Typography>
      )}
    </Box>
  );
}
