import { yupResolver } from '@hookform/resolvers/yup';
import { LoadingButton } from '@mui/lab';
import { Box, Dialog, DialogContent, DialogTitle, Grid, IconButton } from '@mui/material';
import { collection, doc, setDoc } from 'firebase/firestore';
import { useSnackbar } from 'notistack';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import * as Yup from 'yup';
import { DB } from 'src/auth/FirebaseContext';
import { RHFTextField } from 'src/components/hook-form';
import FormProvider from 'src/components/hook-form/FormProvider';
import Iconify from 'src/components/iconify/Iconify';
// eslint-disable-next-line import/no-extraneous-dependencies
import { useQueryClient } from '@tanstack/react-query';
import { queryKeys } from 'src/utils/queryKeys';
// eslint-disable-next-line import/no-extraneous-dependencies
import phone from 'phone';
import { useChat } from 'src/context/ChatContext';
import DeleteConfirmationModal from 'src/sections/chat/header/DeleteConfirmationModal';

type FormValuesProps = {
  email: string;
  phone: string;
};

interface CreateIgnoredContactProps {
  addIgnoredContactModalOpen: boolean;
  handleModalClose: VoidFunction;
}

export default function CreateIgnoredContact({
  addIgnoredContactModalOpen,
  handleModalClose,
}: CreateIgnoredContactProps) {
  const { enqueueSnackbar } = useSnackbar();
  const queryClient = useQueryClient();
  const [ignoredContactLoading, setIgnoredContactLoading] = useState(false);
  const { changeContactID } = useChat();
  useEffect(() => {
    changeContactID(null);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const createIgnoredContactSchema = Yup.object().shape({
    email: Yup.string().required('Email is required').email('Email must be a valid email address'),
    phone: Yup.string()
      .required('Phone Number is required')
      .test('is-valid-phone', 'Phone number is not valid', (value) => {
        if (!value) return false;
        const { isValid } = phone(value, { country: 'USA' });
        return isValid;
      }),
  });

  const defaultValues = {
    email: '',
    phone: '',
  };

  const methods = useForm<FormValuesProps>({
    resolver: yupResolver(createIgnoredContactSchema),
    defaultValues,
  });

  const { reset, handleSubmit } = methods;

  const onSubmit = async (data: FormValuesProps) => {
    setIgnoredContactLoading(true);
    const phoneValidation = phone(data.phone, { country: 'USA' });
    data.phone = phoneValidation.phoneNumber || '';
    const ignoredContactData = { email: data.email, phone: data.phone };
    let response;
    try {
      const docRef = doc(collection(DB, 'ignoredContacts'));

      response = await setDoc(docRef, { ...ignoredContactData, id: docRef.id });
      queryClient.invalidateQueries({ queryKey: [queryKeys.ignoredContacts] });
      enqueueSnackbar('Contact Added Successfully', { variant: 'success' });
    } catch (error) {
      enqueueSnackbar(error.response.data || 'Contact Adding Failed', { variant: 'error' });
    } finally {
      handleModalClose();
      setIgnoredContactLoading(false);
      reset();
    }
    return response;
  };

  const resetModalData = () => {
    handleModalClose();
    reset();
  };

  return (
    <Dialog open={addIgnoredContactModalOpen} onClose={resetModalData}>
      <DialogTitle
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          pt: 1.4,
          pr: 0.3,
          minWidth: '500px !important',
        }}
      >
        Add Contact
        <IconButton onClick={resetModalData}>
          <Iconify icon="radix-icons:cross-2" />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
          <Grid container justifyContent="space-between">
            <Grid item md={12}>
              <RHFTextField name="email" label="Email" sx={{ mt: '10px' }} />
            </Grid>
          </Grid>
          <Grid container justifyContent="space-between">
            <Grid item md={12}>
              <RHFTextField name="phone" label="Phone" sx={{ mt: '10px' }} />
            </Grid>
          </Grid>

          <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
            <DeleteConfirmationModal
              title="Warning"
              content="This will remove the user from the system, delete all historical conversations, and block them from future conversations. Historical data deletion cannot be undone, but you can remove them from the Do Not Contact list later.
              Are you sure you want to proceed?"
              handleDelete={handleSubmit(onSubmit)}
              loading={ignoredContactLoading}
            >
              <LoadingButton variant="contained" sx={{ my: 3 }}>
                Save
              </LoadingButton>
            </DeleteConfirmationModal>
          </Box>
        </FormProvider>
      </DialogContent>
    </Dialog>
  );
}
