/* eslint-disable class-methods-use-this */
import dayjs from 'dayjs';
import {
  collection,
  getDocs,
  query,
  orderBy,
  doc,
  DocumentSnapshot,
  getDoc,
} from 'firebase/firestore';
import { DB } from 'src/auth/FirebaseContext';
import { IFormValuesProps } from 'src/sections/notifications/utils';
import { GeneralType } from 'src/utils/types';

class FirebaseService {
  async getCampaigns(): Promise<GeneralType[]> {
    const campaignsQuery = query(collection(DB, 'campaigns'), orderBy('createdAt', 'desc'));

    const snapshot = await getDocs(campaignsQuery);

    const campaigns = snapshot.docs.map((item) => ({ id: item.id, ...item.data() }));

    return campaigns;
  }

  async getChannels(): Promise<GeneralType[]> {
    const q = query(collection(DB, 'channels'));
    const snapshot = await getDocs(q);
    const channels = snapshot.docs.map((item) => ({
      id: item.id,
      ...item.data(),
    }));
    return channels;
  }

  async getNotes(campaignID: string, contactID: string): Promise<GeneralType[]> {
    const notesRef = query(collection(DB, `campaigns/${campaignID}/contacts/${contactID}/notes`));
    const snapshot = await getDocs(notesRef);
    const notes = snapshot.docs.map((item) => ({ id: item.id, ...item.data() }));
    return notes;
  }

  async getUsers(): Promise<GeneralType[]> {
    const UsersRef = collection(DB as any, 'users');
    const snapshot = await getDocs(UsersRef);
    const usersList = snapshot.docs.map((item) => ({
      id: item.id,
      ...item.data(),
    }));
    return usersList;
  }

  async getIgnoredContacts(): Promise<GeneralType[]> {
    const IgnoredContactsRef = collection(DB as any, `ignoredContacts`);
    const snapshot = await getDocs(IgnoredContactsRef);

    const contacts = snapshot.docs.map((item) => ({
      id: item.id,
      ...item.data(),
    }));
    return contacts;
  }

  async getNotifications(campaignId: string): Promise<IFormValuesProps[]> {
    const NotificationsRef = query(collection(DB, `campaigns/${campaignId}/notifications`));
    const snapshot = await getDocs(NotificationsRef);

    const notifications = snapshot.docs.map((item: any) => {
      const fetchedItem = item.data();

      return {
        id: fetchedItem.id,
        name: fetchedItem.name,
        service: fetchedItem.service,
        dateAndTime: dayjs(fetchedItem.dateAndTime.seconds * 1000),
        templateId: fetchedItem.templateId,
        group: fetchedItem.group,
        isPassed: dayjs(fetchedItem.dateAndTime.seconds * 1000).toDate() < new Date(),
      };
    });
    return notifications;
  }

  async getReport(): Promise<string> {
    const reportRef = collection(DB as any, 'configuration');
    const snapshot = await getDocs(reportRef);
    const report = snapshot.docs.map((item) => item.data())[0]?.report;
    return report;
  }

  async getSingleCampaign(campaignID: string): Promise<{ [key: string]: any }> {
    const campaignRef = doc(DB, `campaigns/${campaignID}`);
    const campaignSnapshot: DocumentSnapshot = await getDoc(campaignRef);

    const campaignData = campaignSnapshot.data() || [];
    return campaignData;
  }

  async getSubstitutionParameters(campaignId: string): Promise<GeneralType[]> {
    const substitutionParameterRef = collection(
      DB as any,
      `campaigns/${campaignId}/substitutionParameters`
    );
    const snapshot = await getDocs(substitutionParameterRef);
    const parameters = snapshot.docs.map((item: any) => {
      const fetchedItem = item.data();
      return {
        id: item.id,
        name: fetchedItem.name,
        type: fetchedItem.type,
        value: fetchedItem.value,
        alias: fetchedItem.alias,
      };
    });
    return parameters;
  }

  async getFaq(): Promise<GeneralType[]> {
    const faqRef = collection(DB as any, `faq`);
    const snapshot = await getDocs(faqRef);

    const faqs = snapshot.docs.map((item) => ({
      id: item.id,
      ...item.data(),
    }));
    return faqs;
  }

  async getSingleUser(userId: string): Promise<{ [key: string]: any }> {
    const userRef = doc(DB, `users/${userId}`);
    const userSnapshot: DocumentSnapshot = await getDoc(userRef);

    const userData = userSnapshot.data() || {};
    return userData;
  }
}

export default FirebaseService;
