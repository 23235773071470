import { useQuery } from '@tanstack/react-query';
import FirebaseService from '../services/firebaseService';
import { queryKeys } from '../utils/queryKeys';

const useReport = () =>
  useQuery<string>({
    queryKey: [queryKeys.report],
    queryFn: () => new FirebaseService().getReport(),
  });

export default useReport;
