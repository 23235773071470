export enum USER_STATUS {
  active = 'active',
  inActive = 'inActive',
}
export enum User_Role {
  admin = 'admin',
  support = 'support',
}
export enum Progress_Status {
  start = 'start',
  completed = 'completed',
}

export const allowedFileTypes = [
  'application/pdf',
  'text/html',
  'text/plain',
  'text/csv',
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  'application/vnd.openxmlformats-officedocument.presentationml.presentation',
];
