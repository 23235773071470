import { useEffect, useState } from 'react';
import { FirestoreError, doc, getDoc } from 'firebase/firestore';
import { DB } from 'src/auth/FirebaseContext';

interface CampaignContacts {
  [key: string]: any;
}
const useCampaignContacts = (campaignId: string, contactId: string) => {
  const [data, setData] = useState<CampaignContacts | null>();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<FirestoreError | null>(null);

  const fetchContact = async () => {
    try {
      const contactRef = doc(DB, `campaigns/${campaignId}/contacts/${contactId}`);
      const contactSnap = await getDoc(contactRef);
      if (contactSnap.exists()) {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const { contexts, ...rest } = contactSnap.data();
        setData(rest);
      } else {
        setData(null);
      }
    } catch (err) {
      setError(err);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchContact();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [campaignId, contactId]);

  return { data, loading, error, fetchContact };
};

export default useCampaignContacts;
