import { yupResolver } from '@hookform/resolvers/yup';
import { LoadingButton } from '@mui/lab';
import { Box, Dialog, DialogContent, DialogTitle, IconButton } from '@mui/material';
import { addDoc, collection, updateDoc } from 'firebase/firestore';
import { useSnackbar } from 'notistack';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import * as Yup from 'yup';
import { DB, storage } from 'src/auth/FirebaseContext';
import { RHFTextField } from 'src/components/hook-form';
import FormProvider from 'src/components/hook-form/FormProvider';
import Iconify from 'src/components/iconify/Iconify';
import { useQueryClient } from '@tanstack/react-query';
import { queryKeys } from 'src/utils/queryKeys';
import { getDownloadURL, ref, uploadBytes } from 'firebase/storage';
import AppUploadFile from './UploadLogo';

type FormValuesProps = {
  campaignAlias: string;
  campaignName: string;
  agentProjectId: string;
  agentId: string;
  webFormDescription: string;
  logo: File | null;
};

interface AddCampaignModalProps {
  campaignModalOpen: boolean;
  handleModalClose: VoidFunction;
}

export default function AddCampaignModal({
  campaignModalOpen,
  handleModalClose,
}: AddCampaignModalProps) {
  const { enqueueSnackbar } = useSnackbar();
  const [isCampaignLoading, setIsCampaignLoading] = useState(false);
  const queryClient = useQueryClient();

  const campaignSchema = Yup.object().shape({
    campaignName: Yup.string().required('Conversation Name is required'),
    agentProjectId: Yup.string().required('Project ID is required'),
    agentId: Yup.string().required('Agent ID is required'),
    webFormDescription: Yup.string().required('Web Form Description is required'),
    logo: Yup.mixed().required('Logo file is required'),
  });

  const defaultValues = {
    campaignName: '',
    agentProjectId: 'staging-early-enrollment-hlcw',
    agentId: '',
    webFormDescription: '',
    logo: null,
  };

  const methods = useForm<FormValuesProps>({
    resolver: yupResolver(campaignSchema),
    defaultValues,
  });

  const { reset, handleSubmit } = methods;

  const onSubmit = async (data: FormValuesProps) => {
    try {
      setIsCampaignLoading(true);
      const colRef = collection(DB, 'campaigns');
      const restartMode = false;
      const projectId = DB?.app?.options?.projectId;
      console.log('projectId', projectId);

      if (data.logo) {
        const newCampaignRef = await addDoc(colRef, {
          campaignAlias: data?.campaignName.toLowerCase().replace(/\s+/g, '_'),
          campaignName: data?.campaignName,
          createdAt: new Date(),
          restartMode,
          agentProjectId: data?.agentProjectId,
          agentId: data?.agentId,
          webFormDescription: data?.webFormDescription,
          logoURL: '',
        });
        // @ts-ignore
        const storageRef = ref(storage, `logos/${newCampaignRef.id}`);
        // @ts-ignore
        await uploadBytes(storageRef, data.logo.file);
        const downloadURL = await getDownloadURL(storageRef);
        await updateDoc(newCampaignRef, { logoURL: downloadURL });
      } else {
        enqueueSnackbar('Please upload a logo file', { variant: 'warning' });
        return;
      }

      queryClient.invalidateQueries({ queryKey: [queryKeys.campaigns] });

      enqueueSnackbar('Conversation Created Successfully', { variant: 'success' });
    } catch (error) {
      console.log('error', error);
      enqueueSnackbar('Conversation Creation Failed', { variant: 'error' });
    } finally {
      handleModalClose();
      setIsCampaignLoading(false);
      reset();
    }
  };

  const resetModalData = () => {
    handleModalClose();
    reset();
  };

  return (
    <Dialog open={campaignModalOpen} onClose={resetModalData}>
      <DialogTitle
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          pt: 1.4,
          pr: 0.3,
          minWidth: '500px !important',
        }}
      >
        Add Conversation
        <IconButton onClick={resetModalData}>
          <Iconify icon="radix-icons:cross-2" />
        </IconButton>
      </DialogTitle>
      <DialogContent sx={{ py: 2 }}>
        <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
          <RHFTextField name="campaignName" label="Conversation Name" sx={{ mt: '10px' }} />
          <RHFTextField name="agentProjectId" label="Agent Project Id" sx={{ mt: '20px' }} />
          <RHFTextField name="agentId" label="Agent Id" sx={{ mt: '20px' }} />
          <RHFTextField
            name="webFormDescription"
            label="Web Form Description"
            sx={{ mt: '20px' }}
            multiline
            minRows={3}
          />
          <AppUploadFile name="logo" label="Upload Logo" maxFiles={1} />

          <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
            <LoadingButton variant="contained" loading={isCampaignLoading} type="submit">
              Save
            </LoadingButton>
          </Box>
        </FormProvider>
      </DialogContent>
    </Dialog>
  );
}
