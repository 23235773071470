/* eslint-disable no-nested-ternary */
import {
  Box,
  Divider,
  IconButton,
  MenuItem,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography,
} from '@mui/material';
import { Key, useState } from 'react';
import { format } from 'date-fns';
import { addDoc, collection, deleteDoc, doc, getDoc, setDoc, updateDoc } from 'firebase/firestore';
import { useSnackbar } from 'notistack';
import Iconify from 'src/components/iconify/Iconify';
import MenuPopover from 'src/components/menu-popover/MenuPopover';
import { DB, storage } from 'src/auth/FirebaseContext';
import DeleteConfirmationModal from 'src/sections/chat/header/DeleteConfirmationModal';
import useCampaignParameters from 'src/hooks/useCampaignParameters';
import useSubstitutionParameters from 'src/hooks/useSubstitutionParameters';
import { useChat } from 'src/context/ChatContext';
import { useQueryClient } from '@tanstack/react-query';
import useCampaigns from 'src/hooks/useCampaigns';
import { queryKeys } from 'src/utils/queryKeys';
import { deleteObject, ref } from 'firebase/storage';
import axios from 'axios';
import { cloneLogo } from 'src/utils/siteVariables';
import CampaignsTableHead from './CampaignsTableHead';
import CampaignEditModal from './CampaignEditModal';
import TableLoader from './TableLoader';
import GenerateCodeModal from './GenerateCodeModal';

// =================================================================================================
const headLabel = [
  { id: 'campaignName', label: 'Conversation Name' },
  { id: 'campaignAlias ', label: 'Conversation Alias' },
  { id: 'agentProjectId ', label: 'Agent Project Id' },
  { id: 'agentId ', label: 'Agent Id' },
  { id: 'createdAt ', label: 'Created At' },
  { id: '' },
];
// ===================================================================================================

export default function CampaignsTable() {
  const { enqueueSnackbar } = useSnackbar();
  const { setCampaignID } = useChat();
  const { data: campaignsData, isLoading: campaignsDataLoading } = useCampaigns();
  const [openEditModal, setOpenEditModal] = useState(false);
  const [selectedCampaignId, setSelectedCampaignId] = useState('');
  const [campaignDeletionLoading, setCampaignDeletionLoading] = useState(false);
  const queryClient = useQueryClient();

  const handleCampaignDeletion = async () => {
    if (!selectedCampaignId) return;
    try {
      setCampaignDeletionLoading(true);
      const logoUrl = campaignsData?.filter((item: any) => item.id === selectedCampaignId)?.[0]
        ?.logoURL;
      if (logoUrl) {
        const path = logoUrl
          .split('/o/')[1]
          .split('?')[0]
          .replace(/%2F/g, '/')
          .replace(/%3A/g, ':');
        const fileRef = ref(storage, path);
        await deleteObject(fileRef);
      }

      const docRef = doc(DB, 'campaigns', selectedCampaignId);
      await deleteDoc(docRef);
      setSelectedCampaignId('');
      queryClient.invalidateQueries({ queryKey: [queryKeys.campaigns] });
      setCampaignID('empty');
      enqueueSnackbar('Conversation Deleted', { variant: 'success' });
    } catch (error) {
      enqueueSnackbar('Conversation Deletion Failed', { variant: 'error' });
    } finally {
      setCampaignDeletionLoading(false);
    }
  };

  const handlePurge = async () => {
    if (!selectedCampaignId) return;
    try {
      const docRef = doc(DB, 'campaigns', selectedCampaignId);
      await updateDoc(docRef, {
        purge: 'start',
      });
      setSelectedCampaignId('');
      setCampaignID('empty');
      queryClient.invalidateQueries({ queryKey: [queryKeys.campaigns] });
      enqueueSnackbar('Purge process started for the conversation.', { variant: 'success' });
    } catch (error) {
      enqueueSnackbar('Failed to start purge process for the conversation.', { variant: 'error' });
      console.log('error', error);
    } finally {
      setCampaignDeletionLoading(false);
    }
  };

  const cloneCampaign = async (
    campaignId: any,
    parameterSetupData: any,
    substitutionParametersData: any
  ) => {
    try {
      const logoUrl = campaignsData?.filter((item: any) => item.id === campaignId)?.[0]?.logoURL;
      let clonedFileUrl;
      const campaignRef = collection(DB as any, `campaigns`);
      const campaignDocRef = doc(campaignRef, campaignId);
      const campaignDocSnapshot = await getDoc(campaignDocRef);
      const campaignData = campaignDocSnapshot.data();
      const clonedCampaignRef = await addDoc(campaignRef, {
        ...campaignData,
        campaignAlias: `${campaignData?.campaignAlias}_cloned`,
        campaignName: `${campaignData?.campaignName}_cloned`,
        logoURL: '',
        createdAt: new Date(),
      });
      const clonedCampaignId = (await getDoc(clonedCampaignRef)).id;
      if (logoUrl) {
        const projectIdFromDB = DB?.app?.options?.projectId;
        const projectId = projectIdFromDB || process.env.PROJECT_ID;
        const response = await axios.get(
          `https://${projectId}.web.app${cloneLogo}?logoUrl=${logoUrl}&&campaignId=${clonedCampaignId}`
        );
        clonedFileUrl = response?.data?.clonedFileUrl;
      }
      await updateDoc(clonedCampaignRef, { logoURL: clonedFileUrl });

      parameterSetupData.map(async (single: any) => {
        const userRef = doc(
          collection(DB, `campaigns/${clonedCampaignId}/parameterSetup`),
          single.id
        );
        const res = await setDoc(userRef, {
          name: single.name,
          values: single.values,
        });
        return res;
      });

      const substitutionParametersRef = collection(
        DB as any,
        `campaigns/${clonedCampaignId}/substitutionParameters`
      );
      substitutionParametersData.map((single: any) => {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const { id, ...rest } = single;
        return addDoc(substitutionParametersRef, {
          ...rest,
          value: single.value || null,
          createdAt: new Date(),
        });
      });
      queryClient.invalidateQueries({ queryKey: [queryKeys.campaigns] });

      enqueueSnackbar('Conversation Cloned Successfully', { variant: 'success' });
    } catch (error) {
      console.log('error', error);
      enqueueSnackbar('Conversation Cloning Failed', { variant: 'error' });
    }
  };

  return (
    <>
      <TableContainer sx={{ overflow: 'hidden', height: '100%' }}>
        <Table sx={{ minWidth: 720 }}>
          <CampaignsTableHead headLabel={headLabel} />

          <TableBody>
            {campaignsDataLoading ? (
              <TableLoader headLabel={headLabel} />
            ) : (
              campaignsData?.map((row: { id: Key | null | undefined }) => (
                <CampaignsRow
                  key={row?.id}
                  row={row}
                  handleModalActions={(id: string) => {
                    setSelectedCampaignId(id);
                    setOpenEditModal(true);
                  }}
                  setSelectedCampaignId={setSelectedCampaignId}
                  cloneCampaign={cloneCampaign}
                  handleCampaignDeletion={() => handleCampaignDeletion()}
                  campaignDeletionLoading={campaignDeletionLoading}
                  handlePurge={() => handlePurge()}
                />
              ))
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <CampaignEditModal
        openEditModal={openEditModal}
        handleCloseEditModal={() => setOpenEditModal(false)}
        selectedCampaignData={campaignsData?.find((data: any) => data.id === selectedCampaignId)}
      />
    </>
  );
}

// ==========================================================================================================

type CampaignsRowProps = {
  row: any;
  handleModalActions: (v: string) => void;
  setSelectedCampaignId: (v: string) => void;
  cloneCampaign: (v: any, x: any, y: any) => void;
  campaignDeletionLoading: boolean;
  handleCampaignDeletion: VoidFunction;
  handlePurge: VoidFunction;
};

function CampaignsRow({
  row,
  handleModalActions,
  setSelectedCampaignId,
  cloneCampaign,
  campaignDeletionLoading,
  handleCampaignDeletion,
  handlePurge,
}: CampaignsRowProps) {
  const [openPopover, setOpenPopover] = useState<HTMLElement | null>(null);
  const [openGenerateCodeModal, setOpenGenerateCodeModal] = useState(false);
  const { data: parameterSetupData } = useCampaignParameters(row.id);
  const { data: substitutionParametersData } = useSubstitutionParameters(row.id);
  const date = new Date(row.createdAt.seconds * 1000 + row.createdAt.nanoseconds / 1000000);
  const disableButton =
    row?.purge === 'start' || row?.purge === 'processing' || row?.purge === 'completed';

  const handleOpenPopover = (event: React.MouseEvent<HTMLElement>) => {
    setOpenPopover(event.currentTarget);
  };

  const handleClosePopover = () => {
    setOpenPopover(null);
  };

  const handleEdit = () => {
    handleModalActions(row?.id);
    handleClosePopover();
  };

  const handleDeletePopover = () => {
    setSelectedCampaignId(row?.id);
  };

  const handleClone = () => {
    setSelectedCampaignId(row?.id);
    cloneCampaign(row?.id, parameterSetupData, substitutionParametersData);
    handleClosePopover();
  };

  const handleGenerateCode = () => {
    setOpenGenerateCodeModal(true);
    handleClosePopover();
  };

  const handlePurgeConversation = () => {
    setSelectedCampaignId(row?.id);
  };

  return (
    <>
      <TableRow>
        <TableCell>{row?.campaignName || ''}</TableCell>
        <TableCell>{row?.campaignAlias || ''}</TableCell>
        <TableCell>{row?.agentProjectId || ''}</TableCell>
        <TableCell>{row?.agentId || ''}</TableCell>
        <TableCell>{format(date, 'yyyy-MM-dd HH:mm:ss') || ''}</TableCell>
        <TableCell align="right">
          <IconButton color={openPopover ? 'inherit' : 'default'} onClick={handleOpenPopover}>
            <Iconify icon="eva:more-vertical-fill" />
          </IconButton>
        </TableCell>
      </TableRow>
      <MenuPopover
        open={openPopover}
        onClose={handleClosePopover}
        arrow="right-top"
        sx={{ width: 160 }}
      >
        <MenuItem onClick={handleEdit} disabled={disableButton}>
          <Iconify icon="bxs:edit" />
          Edit
        </MenuItem>
        <Divider sx={{ borderStyle: 'dashed' }} />

        <MenuItem
          sx={{ color: 'error.main' }}
          onClick={handleDeletePopover}
          disabled={disableButton}
        >
          <DeleteConfirmationModal
            title="Conversation Deletion"
            content="Are you sure you want to delete this Conversation?"
            loading={campaignDeletionLoading}
            handleDelete={() => {
              handleCampaignDeletion();
              handleClosePopover();
            }}
          >
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <Iconify icon="eva:trash-2-outline" />
              <Typography variant="body2">Delete</Typography>
            </Box>
          </DeleteConfirmationModal>
        </MenuItem>

        <Divider sx={{ borderStyle: 'dashed' }} />

        <MenuItem onClick={handleClone} disabled={disableButton}>
          <Iconify icon="clarity:clone-line" />
          Clone
        </MenuItem>
        <Divider sx={{ borderStyle: 'dashed' }} />
        <MenuItem onClick={handleGenerateCode} disabled={disableButton}>
          <Iconify icon="mdi:code-braces" />
          Generate Code
        </MenuItem>
        <Divider sx={{ borderStyle: 'dashed' }} />

        <MenuItem sx={{ color: 'error.main' }} onClick={handlePurgeConversation}>
          <DeleteConfirmationModal
            title="Purge Conversation"
            content="All contact names, emails, and phone numbers will be permanently deleted from this conversation. This action cannot be undone. Are you sure you want to proceed?"
            handleDelete={() => {
              handlePurge();
              handleClosePopover();
            }}
          >
            <IconButton
              sx={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'center',
                alignItems: 'center',
                p: 0,
                m: 0,
                color: 'error.main',
              }}
              disabled={row?.purge}
            >
              <Iconify icon="mdi:anonymous" />
              <Typography variant="body2">
                {row?.purge === 'start' || row?.purge === 'processing'
                  ? 'Purging'
                  : row?.purge === 'complete'
                  ? 'Purged'
                  : 'Purge'}
              </Typography>
            </IconButton>
          </DeleteConfirmationModal>
        </MenuItem>
      </MenuPopover>
      <GenerateCodeModal
        open={openGenerateCodeModal}
        onClose={() => setOpenGenerateCodeModal(false)}
        agentProjectId={row?.agentProjectId}
        agentId={row?.agentId}
      />
    </>
  );
}
