import { FirestoreError, collection, onSnapshot } from 'firebase/firestore';
import { useEffect, useState } from 'react';
import { DB } from 'src/auth/FirebaseContext';

interface CampaignParameters {
  id: string;
  [key: string]: any;
}

const useCampaignParameters = (campaignId: string) => {
  const [data, setData] = useState<CampaignParameters[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<FirestoreError | null>(null);

  useEffect(() => {
    const parametersRef = collection(DB, `campaigns/${campaignId}/parameterSetup`);

    const unsubscribe = onSnapshot(
      parametersRef,
      {
        includeMetadataChanges: true,
      },
      (snapshot) => {
        const parameters = snapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        setData(parameters);
        setLoading(false);
      },
      (err) => {
        setError(err);
        setLoading(false);
      }
    );

    return () => unsubscribe();
  }, [campaignId]);

  return { data, loading, error };
};

export default useCampaignParameters;
