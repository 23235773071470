import {
  MultiFactorError,
  MultiFactorSession,
  PhoneAuthProvider,
  PhoneMultiFactorGenerator,
  RecaptchaVerifier,
  User,
  getAuth,
  getMultiFactorResolver,
  multiFactor,
} from 'firebase/auth';
import { PATH_AUTH } from 'src/routes/paths';

export const enrollUserInMultiFactorAuthentication = async (otp: string, user: User) => {
  const cred = PhoneAuthProvider.credential(window.verificationId, otp as string);
  const multiFactorAssertion = PhoneMultiFactorGenerator.assertion(cred);
  await multiFactor(user).enroll(multiFactorAssertion);
};

export const completeSignInViaOtp = async (otp: string) => {
  const cred = PhoneAuthProvider.credential(window.verificationId, otp as string);
  const multiFactorAssertion = PhoneMultiFactorGenerator.assertion(cred);
  await window.resolver.resolveSignIn(multiFactorAssertion);
};

export const setupRecaptcha = () => {
  const auth = getAuth();
  window.recaptchaVerifier = new RecaptchaVerifier(
    'recaptcha-container',
    {
      size: 'invisible',
      callback: () => {},
    },
    auth
  );
};

export const initiatePhoneNumberVerification = async (
  phoneNumber: string,
  multiFactorSession: MultiFactorSession
) => {
  setupRecaptcha();
  const auth = getAuth();
  const phoneInfoOptions = {
    phoneNumber,
    session: multiFactorSession,
  };

  const phoneAuthProvider = new PhoneAuthProvider(auth);
  return phoneAuthProvider.verifyPhoneNumber(phoneInfoOptions, window.recaptchaVerifier);
};

export const continueSigningInViaTwoFA = async (
  error: MultiFactorError,
  setError: any,
  setLoading: any,
  navigate: any
) => {
  setupRecaptcha();
  // eslint-disable-next-line react-hooks/rules-of-hooks

  const auth = getAuth();
  const resolver = getMultiFactorResolver(auth, error);
  const selectedIndex = 0;
  // Ask user which second factor to use.
  if (resolver.hints[selectedIndex].factorId === PhoneMultiFactorGenerator.FACTOR_ID) {
    const phoneInfoOptions = {
      multiFactorHint: resolver.hints[selectedIndex],
      session: resolver.session,
    };
    const phoneAuthProvider = new PhoneAuthProvider(auth);
    // Send SMS verification code
    phoneAuthProvider
      .verifyPhoneNumber(phoneInfoOptions, window.recaptchaVerifier)
      .then((verificationId) => {
        // Ask user for the SMS verification code. Then:
        window.verificationId = verificationId;
        window.resolver = resolver;
        navigate(PATH_AUTH.verifyOTP);
      })
      .catch((err) => {
        setError(err.message);
        setLoading(false);
      });
  }
};
