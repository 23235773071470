import { yupResolver } from '@hookform/resolvers/yup';
import { LoadingButton } from '@mui/lab';
import { Box, Dialog, DialogContent, DialogTitle, Grid, IconButton } from '@mui/material';
import { doc, updateDoc } from 'firebase/firestore';
import { useSnackbar } from 'notistack';
import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import * as Yup from 'yup';
import { DB } from 'src/auth/FirebaseContext';
import { RHFTextField } from 'src/components/hook-form';
import FormProvider from 'src/components/hook-form/FormProvider';
import Iconify from 'src/components/iconify/Iconify';
import useIgnoredContacts from 'src/hooks/useIgnoredContacts';
import { useQueryClient } from '@tanstack/react-query';
import { queryKeys } from 'src/utils/queryKeys';
// eslint-disable-next-line import/no-extraneous-dependencies
import phone from 'phone';
import DeleteConfirmationModal from 'src/sections/chat/header/DeleteConfirmationModal';

type FormValuesProps = {
  email: string;
  phone: string;
};

interface EditIgnoredContactModalProps {
  EditIgnoredContactModalOpen: boolean;
  handleModalClose: VoidFunction;
  selectedIgnoredContactId: string;
  setSelectedIgnoredContactId: Dispatch<SetStateAction<string>>;
}

export default function EditUserModal({
  EditIgnoredContactModalOpen,
  handleModalClose,
  selectedIgnoredContactId,
  setSelectedIgnoredContactId,
}: EditIgnoredContactModalProps) {
  const { enqueueSnackbar } = useSnackbar();
  const queryClient = useQueryClient();
  const { data: allIgnoredContactsData } = useIgnoredContacts();

  const [isIgnoredContactLoading, setisIgnoredContactLoading] = useState(false);

  const ignoredContactDocs = allIgnoredContactsData?.filter(
    (item: { id: string }) => item.id === selectedIgnoredContactId
  )?.[0];

  const EditIgnoredContactSchema = Yup.object().shape({
    email: Yup.string().required('Email is required').email('Email must be a valid email address'),
    phone: Yup.string()
      .required('Phone Number is required')
      .test('is-valid-phone', 'Phone number is not valid', (value) => {
        if (!value) return false;
        const { isValid } = phone(value, { country: 'USA' });
        return isValid;
      }),
  });

  const defaultValues = {
    email: '',
    phone: '',
  };

  const methods = useForm<FormValuesProps>({
    resolver: yupResolver(EditIgnoredContactSchema),
    defaultValues,
  });

  const { reset, handleSubmit } = methods;
  const onSubmit = async (data: FormValuesProps) => {
    setisIgnoredContactLoading(true);
    try {
      const phoneValidation = phone(data.phone, { country: 'USA' });
      data.phone = phoneValidation.phoneNumber || '';
      const docRef = doc(DB, 'ignoredContacts', selectedIgnoredContactId);
      await updateDoc(docRef, {
        email: data.email,
        phone: data.phone,
      });
      queryClient.invalidateQueries({ queryKey: [queryKeys.ignoredContacts] });
      enqueueSnackbar('Ignored Contact Edited Successfully', { variant: 'success' });
    } catch (error) {
      enqueueSnackbar(error.response.data || 'Operation Failed', { variant: 'error' });
    } finally {
      handleModalClose();
      setSelectedIgnoredContactId('');
      setisIgnoredContactLoading(false);
      reset();
    }
  };

  const resetModalData = () => {
    handleModalClose();
    reset();
  };

  useEffect(() => {
    methods.setValue('email', ignoredContactDocs?.email);
    methods.setValue('phone', ignoredContactDocs?.phone);
  }, [ignoredContactDocs, methods]);

  return (
    <Dialog open={EditIgnoredContactModalOpen} onClose={resetModalData}>
      <DialogTitle
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          pt: 1.4,
          pr: 0.3,
          minWidth: '500px !important',
        }}
      >
        Edit Contact
        <IconButton onClick={resetModalData}>
          <Iconify icon="radix-icons:cross-2" />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
          <Grid container justifyContent="space-between">
            <Grid item md={12}>
              <RHFTextField name="email" label="Email" sx={{ mt: '10px' }} />
            </Grid>
          </Grid>
          <Grid container justifyContent="space-between">
            <Grid item md={12}>
              <RHFTextField name="phone" label="Phone" sx={{ mt: '10px' }} />
            </Grid>
          </Grid>

          <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
            <DeleteConfirmationModal
              title="Warning"
              content="This will remove the user from the system, delete all historical conversations, and block them from future conversations. Historical data deletion cannot be undone, but you can remove them from the Do Not Contact list later.
              Are you sure you want to proceed?"
              handleDelete={handleSubmit(onSubmit)}
              loading={isIgnoredContactLoading}
            >
              <LoadingButton variant="contained" sx={{ my: 3 }}>
                Save
              </LoadingButton>
            </DeleteConfirmationModal>
          </Box>
        </FormProvider>
      </DialogContent>
    </Dialog>
  );
}
