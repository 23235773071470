import { Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import { ReactNode } from 'react';

interface ConfirmationModalProps {
  confirmationModalOpen: boolean;
  handleCloseModal: () => void;
  children: ReactNode;
  title: string;
  content: string;
}

export default function DeleteContactModal({
  confirmationModalOpen,
  handleCloseModal,
  children,
  title,
  content,
}: ConfirmationModalProps) {
  return (
    <Dialog open={confirmationModalOpen} onClose={handleCloseModal}>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>{content}</DialogContent>
      <DialogActions>{children}</DialogActions>
    </Dialog>
  );
}
