import { useState, ReactNode } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
// components
import { PATH_AUTH, PATH_DASHBOARD } from 'src/routes/paths';
import { USER_STATUS, User_Role } from 'src/utils/enums';
import { Alert, Box } from '@mui/material';
import { supportNavConfig } from 'src/layouts/dashboard/nav/config-navigation';
import LoadingScreen from '../components/loading-screen';
//

import { useAuthContext } from './useAuthContext';

// ----------------------------------------------------------------------

type AuthGuardProps = {
  children: ReactNode;
};

export default function AuthGuard({ children }: AuthGuardProps) {
  const { isAuthenticated, isInitialized, profile } = useAuthContext();

  const { pathname } = useLocation();

  const [requestedLocation, setRequestedLocation] = useState<string | null>(null);

  if (!isInitialized) {
    return <LoadingScreen />;
  }

  if (!isAuthenticated) {
    if (pathname !== requestedLocation) {
      setRequestedLocation(pathname);
    }
    return <Navigate to={PATH_AUTH.login} />;
  }

  if (profile?.status === USER_STATUS.inActive) {
    return (
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          paddingTop: 8,
        }}
      >
        <Alert severity="error">Your account has been deactivated, Please contact admin.</Alert>
      </Box>
    );
  }

  if (requestedLocation && pathname !== requestedLocation) {
    setRequestedLocation(null);
    return <Navigate to={requestedLocation} />;
  }

  if (
    profile?.role === User_Role.support &&
    supportNavConfig.flatMap((section) => section.items).some((item) => item.path !== pathname)
  ) {
    return <Navigate to={PATH_DASHBOARD.chat} />;
  }

  return <> {children} </>;
}
