import { useQuery } from '@tanstack/react-query';
import FirebaseService from '../services/firebaseService';
import { queryKeys } from '../utils/queryKeys';

const useSingleCampaign = (campaignID: string) =>
  useQuery<{ [key: string]: any }>({
    queryKey: [queryKeys.singleCampaign, campaignID],
    queryFn: () => new FirebaseService().getSingleCampaign(campaignID),
  });

export default useSingleCampaign;
