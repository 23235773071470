import * as Yup from 'yup';

import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
// @mui
import { Stack, FormHelperText } from '@mui/material';
import { LoadingButton } from '@mui/lab';
// routes
import {
  completeSignInViaOtp,
  enrollUserInMultiFactorAuthentication,
} from 'src/utils/FirestoreUtil';

import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuthContext } from 'src/auth/useAuthContext';
import { User } from 'firebase/auth';
import { PATH_DASHBOARD } from '../../routes/paths';
// components
import FormProvider, { RHFCodes } from '../../components/hook-form';

// ----------------------------------------------------------------------

type FormValuesProps = {
  code1: string;
  code2: string;
  code3: string;
  code4: string;
  code5: string;
  code6: string;
};

export default function AuthVerifyCodeForm() {
  const navigate = useNavigate();

  const { user } = useAuthContext();

  const [error, setError] = useState<string | undefined>(undefined);
  const [loading, setLoading] = useState(false);

  const VerifyCodeSchema = Yup.object().shape({
    code1: Yup.string().required('Code is required'),
    code2: Yup.string().required('Code is required'),
    code3: Yup.string().required('Code is required'),
    code4: Yup.string().required('Code is required'),
    code5: Yup.string().required('Code is required'),
    code6: Yup.string().required('Code is required'),
  });

  const defaultValues = {
    code1: '',
    code2: '',
    code3: '',
    code4: '',
    code5: '',
    code6: '',
  };

  const methods = useForm({
    mode: 'onChange',
    resolver: yupResolver(VerifyCodeSchema),
    defaultValues,
  });

  const {
    handleSubmit,
    formState: { errors },
  } = methods;

  const onSubmit = async (data: FormValuesProps) => {
    const otp = Object.values(data).join('');
    setLoading(true);
    try {
      if (user) await enrollUserInMultiFactorAuthentication(otp, user as User);
      else await completeSignInViaOtp(otp);
      setTimeout(() => {
        navigate(PATH_DASHBOARD.root);
      }, 1000);
    } catch (err: any) {
      setLoading(false);
      setError(err.message);
      if (err.code === 'auth/invalid-verification-code') setError('Invalid Code');
    }
  };

  return (
    <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
      <Stack spacing={3}>
        <RHFCodes keyName="code" inputs={['code1', 'code2', 'code3', 'code4', 'code5', 'code6']} />

        {(!!errors.code1 ||
          !!errors.code2 ||
          !!errors.code3 ||
          !!errors.code4 ||
          !!errors.code5 ||
          !!errors.code6) && (
          <FormHelperText error sx={{ px: 2 }}>
            Code is required
          </FormHelperText>
        )}

        {error && (
          <FormHelperText error sx={{ px: 2 }}>
            {error}
          </FormHelperText>
        )}

        <LoadingButton
          fullWidth
          size="large"
          type="submit"
          variant="contained"
          loading={loading}
          sx={{ mt: 3 }}
        >
          Verify
        </LoadingButton>
      </Stack>
    </FormProvider>
  );
}
