import { yupResolver } from '@hookform/resolvers/yup';
import { LoadingButton } from '@mui/lab';
import { Dialog, DialogContent, DialogTitle, Box, IconButton } from '@mui/material';
import { doc, updateDoc } from 'firebase/firestore';
import { useSnackbar } from 'notistack';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import * as Yup from 'yup';
import { DB, storage } from 'src/auth/FirebaseContext';
import { RHFTextField } from 'src/components/hook-form';
import FormProvider from 'src/components/hook-form/FormProvider';
import Iconify from 'src/components/iconify/Iconify';
import { useQueryClient } from '@tanstack/react-query';
import { queryKeys } from 'src/utils/queryKeys';
import { getDownloadURL, ref, uploadBytes } from 'firebase/storage';
import AppUploadFile from './UploadLogo';

type FormValuesProps = {
  campaignAlias: string;
  campaignName: string;
  agentProjectId: string;
  agentId: string;
  webFormDescription: string;
  logo: File | null;
};

interface CampaignEditModalProps {
  openEditModal: boolean;
  handleCloseEditModal: () => void;
  selectedCampaignData: any;
}

export default function CampaignEditModal({
  openEditModal,
  handleCloseEditModal,
  selectedCampaignData,
}: CampaignEditModalProps) {
  const [isCampaignLoading, setIsCampaignLoading] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const queryClient = useQueryClient();
  const campaignSchema = Yup.object().shape({
    campaignName: Yup.string().required('Conversation Name is required'),
    agentProjectId: Yup.string().required('Project ID is required'),
    agentId: Yup.string().required('Agent ID is required'),
    webFormDescription: Yup.string().required('Web Form Description is required'),
    logo: Yup.mixed().required('Logo file is required'),
  });

  const defaultValues = {
    campaignName: '',
    agentProjectId: 'staging-early-enrollment-hlcw',
    agentId: '',
    webFormDescription: '',
    logo: null,
  };

  const methods = useForm<FormValuesProps>({
    resolver: yupResolver(campaignSchema),
    defaultValues,
  });

  const { reset, handleSubmit } = methods;

  useEffect(() => {
    reset({
      campaignName: selectedCampaignData?.campaignName || '',
      agentProjectId: selectedCampaignData?.agentProjectId || 'staging-early-enrollment-hlcw',
      agentId: selectedCampaignData?.agentId || '',
      webFormDescription: selectedCampaignData?.webFormDescription || '',
      logo: selectedCampaignData?.logoURL,
    });
  }, [reset, selectedCampaignData]);

  const onSubmit = async (data: FormValuesProps) => {
    try {
      let downloadURL;
      setIsCampaignLoading(true);
      if (data.logo) {
        if (data.logo === selectedCampaignData.logoURL) {
          downloadURL = selectedCampaignData.logoURL;
        } else {
          const path =
            (selectedCampaignData.logoURL &&
              selectedCampaignData.logoURL
                .split('/o/')[1]
                .split('?')[0]
                .replace(/%2F/g, '/')
                .replace(/%3A/g, ':')) ||
            '';

          // @ts-ignore
          const storageRef = path
            ? ref(storage, path)
            : // @ts-ignore
              ref(storage, `logos/${data.logo.file.name}`);
          // @ts-ignore
          await uploadBytes(storageRef, data.logo.file);
          downloadURL = await getDownloadURL(storageRef);
        }

        const newdata = {
          campaignAlias: data?.campaignName.toLowerCase().replace(/\s+/g, '_'),
          campaignName: data?.campaignName,
          agentProjectId: data?.agentProjectId,
          agentId: data?.agentId,
          webFormDescription: data?.webFormDescription,
          logoURL: downloadURL,
        };
        const docRef = doc(DB, 'campaigns', selectedCampaignData?.id);
        await updateDoc(docRef, newdata);
      } else {
        enqueueSnackbar('Please upload a logo file', { variant: 'warning' });
        return;
      }

      queryClient.invalidateQueries({ queryKey: [queryKeys.campaigns] });
      enqueueSnackbar('Conversation Update Successful', { variant: 'success' });
    } catch (error) {
      console.log('error', error);
      enqueueSnackbar('Conversation Update Failed', { variant: 'error' });
    } finally {
      handleCloseEditModal();
      setIsCampaignLoading(false);
    }
  };

  return (
    <Dialog open={openEditModal} onClose={handleCloseEditModal}>
      <DialogTitle
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          pt: 1.4,
        }}
      >
        Edit Conversation
        <IconButton onClick={handleCloseEditModal}>
          <Iconify icon="radix-icons:cross-2" />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
          <RHFTextField name="campaignName" label="Conversation Name" sx={{ mt: '10px' }} />
          <RHFTextField name="agentProjectId" label="Agent Project Id" sx={{ mt: '20px' }} />
          <RHFTextField name="agentId" label="Agent Id" sx={{ mt: '20px' }} />
          <RHFTextField
            name="webFormDescription"
            label="Web Form Description"
            sx={{ mt: '20px' }}
            multiline
            minRows={3}
          />
          <AppUploadFile name="logo" label="Upload Logo" maxFiles={1} />
          <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
            <LoadingButton
              variant="contained"
              loading={isCampaignLoading}
              type="submit"
              sx={{ my: 3 }}
            >
              Save
            </LoadingButton>
          </Box>
        </FormProvider>
      </DialogContent>
    </Dialog>
  );
}
