import { useQuery } from '@tanstack/react-query';
import { IFormValuesProps } from 'src/sections/notifications/utils';
import FirebaseService from '../services/firebaseService';
import { queryKeys } from '../utils/queryKeys';

const useNotifications = (campaignId: string) =>
  useQuery<IFormValuesProps[]>({
    queryKey: [queryKeys.notifications, campaignId],
    queryFn: () => new FirebaseService().getNotifications(campaignId),
  });

export default useNotifications;
