/* eslint-disable no-nested-ternary */
// @mui
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
// hooks
import { useBoolean } from 'src/hooks/useBoolean';

// components
import Iconify from 'src/components/iconify';
import { useCallback, useEffect, useState } from 'react';
import { useSnackbar } from 'notistack';
import { deleteObject, getDownloadURL, getMetadata, listAll, ref } from 'firebase/storage';
import { storage } from 'src/auth/FirebaseContext';
import { ConfirmDialog } from 'src/components/custom-dialog';
import { CircularProgress } from '@mui/material';
import FileManagerNewFolderDialog from './Train/file-manager-new-folder-dialog';
import EmptyContent from './Train/empty-content';
import { useTable } from './Train/table';
import FileManagerTable from './Train/file-manager-table';
//

// ----------------------------------------------------------------------

// ----------------------------------------------------------------------

export default function Train() {
  const [fileData, setFileData] = useState<
    {
      url: string;
      name: string;
      id: string;
      createdAt: string;
      contentType: string;
      size: number;
    }[]
  >([]);
  const [loading, setLoading] = useState(false);
  const upload = useBoolean();
  const confirm = useBoolean();
  const notFound = !fileData.length || loading;
  const { enqueueSnackbar } = useSnackbar();
  const table = useTable({ defaultRowsPerPage: 10 });

  const dataInPage = fileData.slice(
    table.page * table.rowsPerPage,
    table.page * table.rowsPerPage + table.rowsPerPage
  );

  const fetchFiles = async () => {
    const folderPath = 'train/'; // Replace with your folder path in Firebase Storage
    const storageRef = ref(storage, folderPath);
    try {
      setLoading(true);
      const res = await listAll(storageRef);
      // Get metadata and download URLs for all files
      const fileDetails = await Promise.all(
        res.items.map(async (itemRef) => {
          const metadata = await getMetadata(itemRef);
          const url = await getDownloadURL(itemRef);
          // Filter only the supported formats
          return {
            url,
            name: itemRef.name, // File name
            id: itemRef.fullPath, // filePath use to delete from storage bucket
            createdAt: metadata.timeCreated, // Upload time
            contentType: metadata.contentType, // File format (MIME type)
            size: metadata.size, // File size
          };
        })
      );

      // Filter out null entries (unsupported formats)
      setFileData(fileDetails.filter((file) => file !== null) as any[]);
    } catch (error) {
      console.error('Error fetching files:', error);
    } finally {
      setLoading(false); // Set loading to false when fetching completes
    }
  };
  useEffect(() => {
    fetchFiles();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleDeleteItem = useCallback(
    async (id: string) => {
      const storageRef = ref(storage, id); // Reference to the file to be deleted

      try {
        // Delete the file from Firebase Storage
        await deleteObject(storageRef);

        // Update the UI by removing the deleted file from state
        const updatedFileData = fileData.filter((file) => file.id !== id);
        setFileData(updatedFileData);

        // Update the pagination table
        table.onUpdatePageDeleteRow(dataInPage.length);

        enqueueSnackbar('File deleted successfully', { variant: 'success' });
      } catch (error) {
        console.error('Error deleting file:', error);
        enqueueSnackbar('Error deleting file', { variant: 'error' });
      }
    },
    [dataInPage.length, fileData, table, enqueueSnackbar]
  );

  const handleDeleteItems = useCallback(async () => {
    try {
      // Delete files from Firebase Storage
      const deletePromises = table.selected.map(async (id: string) => {
        const fileRef = ref(storage, id); // Create a reference to the file
        await deleteObject(fileRef); // Delete the file
      });

      // Wait for all delete operations to complete
      await Promise.all(deletePromises);

      // After deletion, update the local state by filtering out the deleted rows
      const updatedFileData = fileData.filter((row) => !table.selected.includes(row.id));

      setFileData(updatedFileData);

      // Update pagination and table data
      table.onUpdatePageDeleteRows({
        totalRows: updatedFileData.length,
        totalRowsInPage: dataInPage.length,
        totalRowsFiltered: updatedFileData.length,
      });

      enqueueSnackbar('Selected files deleted successfully', { variant: 'success' });
    } catch (error) {
      console.error('Error deleting files:', error);
      enqueueSnackbar('Error deleting files', { variant: 'error' });
    }
  }, [fileData, table, dataInPage.length, enqueueSnackbar]);

  return (
    <>
      <Container maxWidth={'lg'}>
        <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ mb: 2 }}>
          <Typography variant="h4">Training Files</Typography>
          <Button
            variant="contained"
            startIcon={<Iconify icon="eva:cloud-upload-fill" />}
            onClick={upload.onTrue}
          >
            Upload
          </Button>
        </Stack>

        {loading ? (
          <Stack alignItems="center" sx={{ py: 5 }}>
            <CircularProgress />
          </Stack>
        ) : notFound ? (
          <EmptyContent
            filled
            title="No Data"
            sx={{
              py: 10,
            }}
          />
        ) : (
          <FileManagerTable
            table={table}
            tableData={fileData}
            onDeleteRow={handleDeleteItem}
            notFound={notFound}
            onOpenConfirm={confirm.onTrue}
          />
        )}
      </Container>

      <FileManagerNewFolderDialog
        fetchFiles={fetchFiles}
        open={upload.value}
        onClose={upload.onFalse}
      />
      <ConfirmDialog
        open={confirm.value}
        onClose={confirm.onFalse}
        title="Delete"
        content={
          <>
            Are you sure you want to delete <strong>{table.selected.length}</strong> items?
          </>
        }
        action={
          <Button
            variant="contained"
            color="error"
            onClick={() => {
              handleDeleteItems();
              confirm.onFalse();
            }}
          >
            Delete
          </Button>
        }
      />
    </>
  );
}
