import { yupResolver } from '@hookform/resolvers/yup';
import { LoadingButton } from '@mui/lab';
import { Box, Dialog, DialogContent, DialogTitle, IconButton } from '@mui/material';
import { addDoc, collection } from 'firebase/firestore';
import { useSnackbar } from 'notistack';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import * as Yup from 'yup';
import { DB } from 'src/auth/FirebaseContext';
import { RHFTextField } from 'src/components/hook-form';
import FormProvider from 'src/components/hook-form/FormProvider';
import Iconify from 'src/components/iconify/Iconify';
import { useQueryClient } from '@tanstack/react-query';
import { queryKeys } from 'src/utils/queryKeys';

type FormValuesProps = {
  name: string;
  value: string;
};

interface AddSubstitutionParametersProps {
  SubstitutionParameterModalOpen: boolean;
  handleModalClose: VoidFunction;
  campaignId: any;
}
export enum SUBSTITUTION_PARAMETER_TYPES {
  STATIC = 'STATIC',
  DYNAMIC = 'DYNAMIC',
}

export default function AddSubstitutionParameterModal({
  SubstitutionParameterModalOpen,
  handleModalClose,
  campaignId,
}: AddSubstitutionParametersProps) {
  const { enqueueSnackbar } = useSnackbar();
  const queryClient = useQueryClient();
  const [isSubstitutionParameterLoading, setIsSubstitutionParameterLoading] = useState(false);
  const campaignSchema = Yup.object().shape({
    name: Yup.string().required('Variable Name is required'),
    value: Yup.string().required('Variable Value is required'),
  });

  const defaultValues = {
    name: '',
    value: '',
  };

  const methods = useForm<FormValuesProps>({
    resolver: yupResolver(campaignSchema),
    defaultValues,
  });

  const { reset, handleSubmit } = methods;

  const onSubmit = async (data: FormValuesProps) => {
    try {
      setIsSubstitutionParameterLoading(true);
      const colRef = collection(DB, `campaigns/${campaignId}/substitutionParameters`);
      addDoc(colRef, {
        alias: data?.name.toLowerCase().replace(/\s+/g, '_'),
        name: data?.name,
        value: data?.value,
        type: SUBSTITUTION_PARAMETER_TYPES.DYNAMIC,
        createdAt: new Date(),
      });
      queryClient.invalidateQueries({ queryKey: [queryKeys.substitutionParameters] });
      enqueueSnackbar('Personalization Variable Created Successfully', { variant: 'success' });
    } catch (error) {
      enqueueSnackbar('Personalization Variable Creation Failed', { variant: 'error' });
    } finally {
      handleModalClose();
      setIsSubstitutionParameterLoading(false);
      reset();
    }
  };

  const resetModalData = () => {
    handleModalClose();
    reset();
  };

  return (
    <Dialog open={SubstitutionParameterModalOpen} onClose={resetModalData}>
      <DialogTitle
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          pt: 1.4,
          pr: 0.3,
        }}
      >
        Add Personalization Variable
        <IconButton onClick={resetModalData}>
          <Iconify icon="radix-icons:cross-2" />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
          <RHFTextField name="name" label="Variable Name" sx={{ mb: 2, mt: 2 }} />
          <RHFTextField name="value" label="Variable Value" />
          <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
            <LoadingButton
              variant="contained"
              loading={isSubstitutionParameterLoading}
              type="submit"
              sx={{ my: 3 }}
            >
              Save
            </LoadingButton>
          </Box>
        </FormProvider>
      </DialogContent>
    </Dialog>
  );
}
