/* eslint-disable no-nested-ternary */
import { useSearchParams } from 'react-router-dom';
import WebForm from './WebForm';

export default function WebFormSettings() {
  const [searchParams] = useSearchParams();

  const campaignID = searchParams.get('conversationId') as string;
  const contactID = searchParams.get('chatId') as string;

  return <WebForm contactID={contactID} campaignID={campaignID} />;
}
