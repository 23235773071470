import { createContext, useEffect, useReducer, useCallback, useMemo } from 'react';
import { initializeApp } from 'firebase/app';
import { getPerformance } from 'firebase/performance';
import {
  getAuth,
  signOut,
  signInWithPopup,
  onAuthStateChanged,
  GoogleAuthProvider,
  GithubAuthProvider,
  TwitterAuthProvider,
  createUserWithEmailAndPassword,
} from 'firebase/auth';
import { getFirestore, collection, doc, getDoc, setDoc } from 'firebase/firestore';
import { getStorage } from 'firebase/storage';
// config
import { User_Role } from 'src/utils/enums';
import { FIREBASE_API } from '../config-global';
//
// eslint-disable-next-line import/no-cycle
import { ActionMapType, AuthStateType, AuthUserType, FirebaseContextType } from './types';

// ----------------------------------------------------------------------

export enum Types {
  INITIAL = 'INITIAL',
  LOADING = 'LOADING',
  ERROR = 'ERROR',
}

export enum AVAILABLE_AUTH_PROVIDERS {
  GOOGLE = 'GOOGLE',
  TWITTER = 'TWITTER',
  GITHUB = 'GITHUB',
}

type Payload = {
  [Types.INITIAL]: {
    isAuthenticated: boolean;
    user: AuthUserType;
    profile?: Record<string, any> | null;
  };
  [Types.LOADING]: boolean;
  [Types.ERROR]: null | string;
};

type ActionsType = ActionMapType<Payload>[keyof ActionMapType<Payload>];

// ----------------------------------------------------------------------

export const initialState: AuthStateType = {
  isInitialized: false,
  isAuthenticated: false,
  user: null,
  loading: false,
  error: null,
  profile: null,
};

export const reducer = (state: AuthStateType, action: ActionsType) => {
  switch (action.type) {
    case Types.INITIAL:
      return {
        ...state,
        isInitialized: true,
        isAuthenticated: action.payload.isAuthenticated,
        user: action.payload.user,
        profile: action.payload.profile,
      };
    case Types.LOADING:
      return {
        ...state,
        loading: action.payload,
      };
    case Types.ERROR:
      return {
        ...state,
        error: action.payload,
      };

    default:
      return state;
  }
};

// ----------------------------------------------------------------------

export const AuthContext = createContext<FirebaseContextType | null>(null);

// ----------------------------------------------------------------------

const firebaseApp = initializeApp(FIREBASE_API);

export const auth = getAuth(firebaseApp);
export const perf = getPerformance(firebaseApp);

export const DB = getFirestore(firebaseApp);
const projectId = DB?.app?.options?.projectId;
export const storage = getStorage(firebaseApp);
const GOOGLE_PROVIDER = new GoogleAuthProvider();

const GITHUB_PROVIDER = new GithubAuthProvider();

const TWITTER_PROVIDER = new TwitterAuthProvider();

type AuthProviderProps = {
  children: React.ReactNode;
};

export function AuthProvider({ children }: AuthProviderProps) {
  const [state, dispatch] = useReducer(reducer, initialState);

  const initialize = useCallback(() => {
    try {
      onAuthStateChanged(auth, async (user) => {
        if (user) {
          const userRef = doc(DB, 'users', user.uid);
          const docSnap = await getDoc(userRef);
          const profile = docSnap.data();
          dispatch({
            type: Types.INITIAL,
            payload: {
              isAuthenticated: true,
              user,
              profile: {
                projectId,
                ...profile,
                role: profile?.role || User_Role.support,
              },
            },
          });
        } else {
          dispatch({
            type: Types.INITIAL,
            payload: {
              isAuthenticated: false,
              user: null,
              profile: null,
            },
          });
        }
      });
    } catch (error) {
      console.error(error);
    }
  }, []);

  useEffect(() => {
    initialize();
  }, [initialize]);

  const saveUserToFirebase = async (data: {
    uid: string;
    provider: string;
    email: string;
    displayName: string;
    firstName: string;
    phoneNumber: string;
    photoUrl: string;
    lastName: string;
    address: string;
    companyName: string;
  }) => {
    const userRef = doc(collection(DB, 'users'), data.uid);

    await setDoc(userRef, data);
  };

  const loginWithProvider = useCallback(async (provider: AVAILABLE_AUTH_PROVIDERS) => {
    dispatch({
      type: Types.ERROR,
      payload: null,
    });
    dispatch({
      type: Types.LOADING,
      payload: true,
    });
    try {
      let data: any = {};
      if (provider === AVAILABLE_AUTH_PROVIDERS.GOOGLE) {
        data = await signInWithPopup(auth, GOOGLE_PROVIDER);
      } else if (provider === AVAILABLE_AUTH_PROVIDERS.GITHUB) {
        data = await signInWithPopup(auth, GITHUB_PROVIDER);
      } else if (provider === AVAILABLE_AUTH_PROVIDERS.TWITTER) {
        data = await signInWithPopup(auth, TWITTER_PROVIDER);
      }

      await saveUserToFirebase({
        displayName: data.user?.displayName,
        email: data.user?.email,
        firstName: '',
        lastName: '',
        phoneNumber: data.user?.phoneNumber,
        address: data.user?.address,
        companyName: data.user?.companyName,
        photoUrl: data.user?.photoUrl,
        provider: data.user?.providerId,
        uid: data.user.uid,
      });
    } catch (error) {
      dispatch({
        type: Types.ERROR,
        payload: `Unable to login with  ${provider}`,
      });
    }
    dispatch({
      type: Types.LOADING,
      payload: false,
    });
  }, []);

  // REGISTER
  const register = useCallback(
    (
      email: string,
      phone: string,
      address: string,
      firstName: string,
      lastName: string,
      password: string,
      companyName: string
    ) => {
      createUserWithEmailAndPassword(auth, email, password).then(async (res) => {
        await saveUserToFirebase({
          displayName: `${firstName} ${lastName}`,
          email,
          firstName,
          lastName,
          phoneNumber: phone || res.user?.phoneNumber || '',
          photoUrl: res.user?.photoURL || '',
          provider: 'email',
          uid: res.user?.uid,
          address,
          companyName,
        });
      });
    },
    []
  );

  // LOGOUT
  const logout = useCallback(() => {
    signOut(auth);
  }, []);

  const memoizedValue = useMemo(
    () => ({
      isInitialized: state.isInitialized,
      isAuthenticated: state.isAuthenticated,
      user: state.user,
      profile: state.profile,
      projectId: state.profile?.projectId,
      error: state.error,
      loading: state.loading,
      method: 'firebase',
      loginWithProvider,
      register,
      logout,
      setUser: dispatch,
    }),
    [
      state.isAuthenticated,
      state.isInitialized,
      state.user,
      state.profile,
      loginWithProvider,
      register,
      logout,
      state.error,
      state.loading,
      dispatch,
    ]
  );

  return <AuthContext.Provider value={memoizedValue}>{children}</AuthContext.Provider>;
}
