import { yupResolver } from '@hookform/resolvers/yup';
import { LoadingButton } from '@mui/lab';
import { Box, Dialog, DialogContent, DialogTitle, Grid, IconButton } from '@mui/material';
import { collection, doc, setDoc } from 'firebase/firestore';
import { useSnackbar } from 'notistack';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import * as Yup from 'yup';
import { DB } from 'src/auth/FirebaseContext';
import { RHFTextField } from 'src/components/hook-form';
import FormProvider from 'src/components/hook-form/FormProvider';
import Iconify from 'src/components/iconify/Iconify';
import { useQueryClient } from '@tanstack/react-query';
import { queryKeys } from 'src/utils/queryKeys';
import { useAuthContext } from 'src/auth/useAuthContext';

type FormValuesProps = {
  question: string;
  answer: string;
  title: string;
  url: string;
};

interface CreateFaqProps {
  addFaqModalOpen: boolean;
  handleModalClose: VoidFunction;
}

export default function CreateFaq({ addFaqModalOpen, handleModalClose }: CreateFaqProps) {
  const { enqueueSnackbar } = useSnackbar();
  const { profile } = useAuthContext();
  const queryClient = useQueryClient();
  const [faqLoading, setFaqLoading] = useState(false);

  const createFaqSchema = Yup.object().shape({
    question: Yup.string().required('Qustion is required'),
    answer: Yup.string().required('Answer is required.'),
    title: Yup.string().required('Title is required'),
    url: Yup.string().required('URL is required.'),
  });

  const defaultValues = {
    question: '',
    answer: '',
    title: '',
    url: '',
  };

  const methods = useForm<FormValuesProps>({
    resolver: yupResolver(createFaqSchema),
    defaultValues,
  });

  const { reset, handleSubmit } = methods;

  const onSubmit = async (data: FormValuesProps) => {
    setFaqLoading(true);
    const faqData = {
      title: data.title,
      question: data.question,
      answer: data.answer,
      url: data.url,
    };

    try {
      const docRef = doc(collection(DB, 'faq'));
      await setDoc(docRef, {
        ...faqData,
        id: docRef.id,
        createdAt: new Date(),
        updatedAt: '',
        updatedBy: profile?.uid,
      });
      queryClient.invalidateQueries({ queryKey: [queryKeys.faq] });
      enqueueSnackbar('Faq Added Successfully', { variant: 'success' });
    } catch (error) {
      enqueueSnackbar(error.response.data || 'Faq Adding Failed', { variant: 'error' });
    } finally {
      handleModalClose();
      setFaqLoading(false);
      reset();
    }
  };

  const resetModalData = () => {
    handleModalClose();
    reset();
  };

  return (
    <Dialog open={addFaqModalOpen} onClose={resetModalData}>
      <DialogTitle
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          pt: 1.4,
          pr: 0.3,
          minWidth: '500px !important',
        }}
      >
        Add FAQ
        <IconButton onClick={resetModalData}>
          <Iconify icon="radix-icons:cross-2" />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
          <Grid container justifyContent="space-between">
            <Grid item md={12}>
              <RHFTextField name="title" label="Title" sx={{ mt: '10px' }} />
            </Grid>
          </Grid>
          <Grid container justifyContent="space-between">
            <Grid item md={12}>
              <RHFTextField name="question" label="Question" sx={{ mt: '10px' }} />
            </Grid>
          </Grid>
          <Grid container justifyContent="space-between">
            <Grid item md={12}>
              <RHFTextField name="answer" label="Answer" sx={{ mt: '10px' }} />
            </Grid>
          </Grid>
          <Grid container justifyContent="space-between">
            <Grid item md={12}>
              <RHFTextField name="url" label="URL" sx={{ mt: '10px' }} />
            </Grid>
          </Grid>

          <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
            <LoadingButton variant="contained" loading={faqLoading} type="submit" sx={{ my: 3 }}>
              Save
            </LoadingButton>
          </Box>
        </FormProvider>
      </DialogContent>
    </Dialog>
  );
}
