// form
import { useFormContext, Controller } from 'react-hook-form';
// @mui
import { IconButton, InputAdornment, TextField, TextFieldProps } from '@mui/material';
import Iconify from '../iconify/Iconify';

// ----------------------------------------------------------------------

type Props = TextFieldProps & {
  name: string;
  onRemove: () => void;
};

export default function RHFTextFieldIcon({ name, helperText, onRemove, ...other }: Props) {
  const { control } = useFormContext();

  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) => (
        <TextField
          {...field}
          fullWidth
          value={typeof field.value === 'number' && field.value === 0 ? '' : field.value}
          error={!!error}
          helperText={error ? error?.message : helperText}
          {...other}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  color="error"
                  aria-label="delete"
                  size="small"
                  sx={{ height: 30 }}
                  onClick={onRemove}
                >
                  <Iconify icon="ic:baseline-delete" />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      )}
    />
  );
}
