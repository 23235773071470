import { addDoc, collection, orderBy, query } from 'firebase/firestore';
import {
  createContext,
  useState,
  ReactElement,
  useContext,
  useEffect,
  useMemo,
  useCallback,
} from 'react';
import { useCollection, useCollectionData } from 'react-firebase-hooks/firestore';
import { useSearchParams } from 'react-router-dom';
import { DB } from 'src/auth/FirebaseContext';
import { useAuthContext } from 'src/auth/useAuthContext';
// ----------------------------------------------------------------------

export interface Contact {
  group: string;
  createdAt: Date;
  email: string;
  error: string;
  name: string;
  humanAssistance: boolean;
  phone: string;
  completed: boolean;
  updatedAt: Date;
  updatedBy: string;
  id: string;
  unRead: boolean;
  lastMsgTime: any;
  response: boolean;
  noResponse: boolean;
}
export interface MessageInput {
  msgContent: string;
  sender_id: string;
}

export interface ChatMessage {
  deliveredAt?: Date;
  intent?: any;
  sender?: string;
  msgContent: string;
  providerMessageId?: string;
  receiver?: string;
  sentAt?: Date;
  type?: string;
}

type Contacts = { [key: string]: Contact };

type ChatType = {
  contactID: any;
  changeContactID: (userID: string | null) => void;
  contacts: Contacts;
  loading: boolean;
  messages: ChatMessage[];
  handleSendMessage: (value: MessageInput) => void;
  resetContext: () => void;
  changeCampaignID: (value: any) => void;
  campaignID: string;
  parameters: any;
  setCampaignID: (e: string) => void;
  setContacts: React.Dispatch<React.SetStateAction<Contacts>>;
};

const initialState: ChatType = {
  contactID: '',
  changeContactID: () => {},
  contacts: {},
  loading: false,
  messages: [],
  handleSendMessage: () => {},
  resetContext: () => {},
  changeCampaignID: () => {},
  campaignID: '',
  parameters: {},
  setCampaignID: () => {},
  setContacts: () => {},
};

const ChatContext = createContext(initialState);

// ----------------------------------------------------------------------

function ChatProvider({ children }: { children: ReactElement }) {
  const { user } = useAuthContext();
  const [contactID, setContactID] = useState<string | undefined>();
  const [contacts, setContacts] = useState<Contacts>({});
  const [messages, setMessages] = useState<ChatMessage[]>([]);
  const [parameters, setParameters] = useState<any>([]);
  const [campaignID, setCampaignID] = useState('empty');
  const [selectedContact, setSelectedContact] = useState<undefined | Contact>();
  const [searchParams] = useSearchParams();
  const paramsCampaignId = searchParams.get('conversationId');
  const paramsChatId = searchParams.get('chatId');

  const [contactsData, loading] = useCollection(
    query(collection(DB, `campaigns/${campaignID}/contacts`), orderBy('createdAt', 'desc'))
  );

  const messageRef = collection(
    DB as any,
    'campaigns',
    campaignID,
    'contacts',
    contactID || '123',
    'communications'
  );

  const parameterRef = collection(
    DB as any,
    'campaigns',
    campaignID,
    'contacts',
    contactID || '123',
    'parameters'
  );

  const [messagesFromDB] = useCollectionData(query(messageRef, orderBy('sentAt', 'asc')));
  const [parametersFromDB] = useCollection(query(parameterRef));

  const getContactData = useCallback((contactId: any) => contacts[contactId], [contacts]);

  useEffect(() => {
    const tempMsg =
      messagesFromDB?.map((single: any) => ({
        ...single,
      })) || [];
    // @ts-ignore
    setMessages(tempMsg);
  }, [messagesFromDB]);

  useEffect(() => {
    const tempParam = parametersFromDB?.docs.reduce(
      (prev, curr) => ({ ...prev, [curr?.id]: curr?.data() }),
      {}
    );
    setParameters(tempParam);
  }, [parametersFromDB]);

  useEffect(() => {
    if (paramsCampaignId) setCampaignID(paramsCampaignId);
    if (paramsChatId) changeContactID(paramsChatId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (contacts && contactID) setSelectedContact(contacts[contactID]);
  }, [contactID, contacts]);

  useEffect(() => {
    if (!user?.uid || !contactsData) {
      return;
    }

    const tempContacts = contactsData?.docs.reduce((prev, curr) => {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const { contexts, ...filteredData } = curr.data();
      const abc = { id: curr.id, ...filteredData };
      return { ...prev, [curr.id]: abc };
    }, {});
    // @ts-ignore
    setContacts(tempContacts);
  }, [contactsData, contactID, user?.uid]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const changeContactID = async (uID: any) => {
    setContactID(uID);
  };

  const changeCampaignID = (e: any) => {
    setCampaignID(e.target.value);
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleSendMessage = async ({ msgContent, sender_id }: MessageInput) => {
    if (!contactID) {
      return;
    }
    await addDoc(messageRef, {
      deliveredAt: null,
      intent: null,
      sender: sender_id,
      msgContent,
      providerMessageId: null,
      receiver: contacts[contactID].phone,
      sentAt: new Date(),
      type: 'user',
    });
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const resetContext = () => {
    setCampaignID('empty');
    setContacts({});
    setContactID(undefined);
    setMessages([]);
    setParameters({});
    setSelectedContact(undefined);
  };

  const memoizedValue = useMemo(
    () => ({
      contactID,
      changeContactID,
      contacts,
      setContacts,
      loading,
      messages,
      handleSendMessage,
      resetContext,
      getContactData,
      changeCampaignID,
      campaignID,
      parameters,
      setCampaignID,
      selectedContact,
    }),
    [
      contactID,
      changeContactID,
      contacts,
      setContacts,
      loading,
      messages,
      handleSendMessage,
      resetContext,
      getContactData,
      campaignID,
      parameters,
      setCampaignID,
      selectedContact,
    ]
  );

  return <ChatContext.Provider value={memoizedValue}>{children}</ChatContext.Provider>;
}

const useChat = () => useContext(ChatContext);

export { ChatProvider, useChat };
