import { useEffect, useRef, DependencyList } from 'react';

type EffectCallback = () => void | (() => void);

function useEffectSkipFirst(effect: EffectCallback, deps: DependencyList): void {
  const isInitialRender = useRef(true);

  useEffect(() => {
    // Skip the effect on the initial render
    if (isInitialRender.current) {
      isInitialRender.current = false;
      return;
    }

    // Run the effect
    const cleanup = effect();

    if (cleanup && typeof cleanup === 'function') {
      // eslint-disable-next-line consistent-return
      return cleanup;
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, deps); // Pass dependencies to the inner useEffect
}

export default useEffectSkipFirst;
