import { TextareaAutosize } from '@mui/material';

export default function TextArea({ ...field }) {
  return (
    <>
      <TextareaAutosize
        {...field}
        className="CustomTextareaIntrocudtion"
        placeholder="Add note"
        minRows={3}
        maxRows={3}
        style={{ resize: 'none' }}
      />
      <Styles />
    </>
  );
}

const grey = {
  50: '#F3F6F9',
  100: '#E5EAF2',
  200: '#DAE2ED',
  300: '#C7D0DD',
  400: '#B0B8C4',
  500: '#9DA8B7',
  600: '#6B7A90',
  700: '#434D5B',
  800: '#303740',
  900: '#1C2025',
};

function Styles() {
  return (
    <style>
      {`
      .CustomTextareaIntrocudtion {
        min-width:100%;
        max-width:100%;
        font-size: 0.875rem;
        font-weight: 400;
        line-height: 1.5;
        padding: 12px;
        border-radius: 12px 12px 0 12px;
        color: ${grey[900]};
        background: none;
        border: 1px solid ${grey[100]};
      }

   

     .CustomTextareaIntrocudtion:focus {
        border: 1px solid ${grey[100]};
        outline: none;
      }

      // firefox
      .CustomTextareaIntrocudtion:focus-visible {
        outline: 0;
      }
    `}
    </style>
  );
}
